import React, { useContext, useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension, Select2Data } =
    useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/birthday/${id}`);
    reset(response?.data);

    setVideoPreview(IMG_URL + response?.data?.video);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();
      finalData.append("message", data?.message);
      if (data?.video) {
        finalData.append("video", data?.video[0]);
      }
      finalData.append(
        "product_id",
        JSON.stringify(data?.product_id?.map((item) => item?.value))
      );
      const response = await postData(`/birthday/${id}`, finalData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  const [videoFile, setVideoFile] = useState(null);

  const [mediaFile, setMediaFile] = useState(null);

  // Handle media file selection
  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMediaFile(file);
      setValue("media", file); // Register file for react-hook-form
    }
  };

  // Helper to determine if the file is a video
  const isVideoFile = (fileName) => {
    const videoExtensions = ["mp4", "mov", "avi"];
    const extension = fileName.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  const [products, setProduct] = useState([]);

  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProduct(await Select2Data(response?.data, "product_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
  }, []);

  const [mediaPreview, setMediaPreview] = useState(null);

  // const handleMediaChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const fileType = file.type.split("/")[0]; // 'image' or 'video'
  //     const preview = URL.createObjectURL(file);

  //     console.log(fileType, "fileType");
  //     console.log(preview, "preview");

  //     setMediaPreview({
  //       src: preview,
  //       type: fileType,
  //     });
  //   }
  // };
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Message</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="message"
                          placeholder="Message"
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}
                          {...register("message", {
                            required: "Message is required",
                            maxLength: {
                              value: maxCharLimit,
                              message: `Name cannot exceed ${maxCharLimit} characters`,
                            },
                          })}
                          maxLength={maxCharLimit}
                          onChange={handleInputChange}
                        />
                      </InputGroup>{" "}
                      <div className="text-end">
                        <small>{maxCharLimit} characters Limit </small>
                      </div>
                      {errors.message && (
                        <span className="text-danger">
                          {errors.message.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">
                      Media (Image/Video)
                    </Form.Label>
                    <Form.Group>
                      <Form.Control
                        className={errors?.video ? "is-invalid" : ""}
                        type="file"
                        accept=".mp4, .mov, .avi, .jpg, .jpeg, .png, .gif"
                        {...register("video")}
                        onChange={handleMediaChange}
                      />
                    </Form.Group>
                    {errors.video && (
                      <span className="text-danger">
                        {errors.video.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Media Preview
                    </Form.Label>
                    <Col sm={9}>
                      {mediaFile && (
                        <div className="media-preview-container">
                          {isVideoFile(mediaFile.name) ? (
                            <video
                              src={URL.createObjectURL(mediaFile)}
                              className="media-preview"
                              style={{ width: "150px", height: "130px" }}
                              controls
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(mediaFile)}
                              alt="Preview"
                              className="media-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Media Preview
                    </Form.Label>
                    <Col sm={9}>
                      {typeof getValues("media") === "string" ? (
                        <div className="media-preview-container">
                          {isVideoFile(getValues("media")) ? (
                            <video
                              src={IMG_URL + getValues("media")}
                              className="media-preview"
                              style={{ width: "150px", height: "130px" }}
                              controls
                            />
                          ) : (
                            <img
                              src={IMG_URL + getValues("media")}
                              alt="Preview"
                              className="media-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          )}
                        </div>
                      ) : (
                        mediaFile &&
                        mediaFile.length > 0 && (
                          <div className="media-preview-container">
                            {isVideoFile(mediaFile[0].name) ? (
                              <video
                                src={URL.createObjectURL(mediaFile[0])}
                                className="media-preview"
                                style={{ width: "150px", height: "130px" }}
                                controls
                              />
                            ) : (
                              <img
                                src={URL.createObjectURL(mediaFile[0])}
                                alt="Preview"
                                className="media-preview"
                                style={{ width: "150px", height: "130px" }}
                              />
                            )}
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="col-lg-12">
                  <div className="field-bottom mb-3">
                    <Form.Label className="required">Products</Form.Label>
                    <Controller
                      name="product_id" // name of the field
                      control={control}
                      rules={{ required: "Select Categories" }}
                      render={({ field }) => (
                        <Select
                          isMulti
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.product_id
                                ? "red"
                                : baseStyles.borderColor,
                            }),
                          }}
                          {...field}
                          options={products}
                        />
                      )}
                    />
                    {errors.product_id && (
                      <span className="text-danger">
                        {errors.product_id.message}
                      </span>
                    )}
                  </div>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
