import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const EditOffCanvance = (props) => {
  const {
    postData,
    getData,
    Select2Data,
    IMG_URL,
    getDimension,
    deleteData,
    isAllow,
    usertype,
    SelectUsers,
    userdata,
  } = useContext(Context);

  const id = props.show;
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [subAttributes, setSubAttribute] = useState([]);
  const [seller, setSellers] = useState([]);
  const [imageStatus, setImageStatus] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [weightOrPiece, setWeightOrPiece] = useState([]);
  const [rupeesOrPercent, setRupeesOrPercent] = useState([]);
  const [imageChange, setImageChange] = useState([]);

  const [weightOrPieceStates, setWeightOrPieceStates] = useState([]);

  const [rupeesOrPercentStates, setRupeesOrPercentStates] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");
    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);
    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async () => {
    const response = await getData(`/get-all-Sellers`);
    if (response?.success) {
      setSellers(await SelectUsers(response?.data, "user_id"));
    }
  };

  const GetAllBrands = async () => {
    const response = await getData("/allbrands");
    if (response?.success) {
      setBrands(await Select2Data(response?.data, "brand_id"));
    }
  };

  const GetAllTaxTypes = async () => {
    const response = await getData("/all-tax-type");
    if (response?.success) {
      setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
    }
  };

  const GetAllTaxPercentages = async (id) => {
    const response = await getData(`/all-tax-percentage/${id}`);
    if (response?.success) {
      setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
    }
  };

  const GetAllAttributes = async () => {
    const response = await getData(`/all-attribute`);
    if (response?.success) {
      setAttribute(await Select2Data(response?.data, "attribute_id"));
    }
  };

  const GetAllSubAttributes = async (id) => {
    const response = await getData(`/all-sub-attribute/${id}`);
    if (response?.success) {
      setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
    }
  };

  useEffect(() => {
    GetAllChildSubCategory();
    GetEditData();
    GetAllCategory();
    GetAllBrands();
    GetAllTaxTypes();
    GetAllAttributes();
  }, [id]);

  const GetEditData = async () => {
    const response = await getData(`/product/${id}`);

    const weightOrPieceData = response?.data?.main_m_variants?.flatMap(
      (value) => value?.m_variants?.map((data) => data?.weight_or_piece?.value)
    );

    const rupeesOrPercentData = response?.data?.main_m_variants?.flatMap(
      (value) => value?.m_variants?.map((data) => data?.discount?.value)
    );

    setWeightOrPiece(weightOrPieceData);
    setRupeesOrPercent(rupeesOrPercentData);

    setImageUrl(
      response?.data?.main_m_variants?.map((value) =>
        value?.m_variants?.map((data) =>
          data?.m_variant_images?.map((image) => image?.image)
        )
      )
    );

    reset(response?.data);
  };

  const type = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Multiple",
      label: "Multiple",
    },
  ];

  const membershipType = [
    {
      value: "Silver",
      label: "Silver",
    },
    {
      value: "Gold",
      label: "Gold",
    },
    {
      value: "Platinum",
      label: "Platinum",
    },
  ];

  const weight_or_piece = [
    {
      value: "piece",
      label: "piece",
    },
    {
      value: "weight",
      label: "weight",
    },
  ];

  const percent_or_rupees = [
    {
      value: "rupees",
      label: "rupees",
    },
    {
      value: "percent",
      label: "percent",
    },
  ];

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      category_id: "",
      type: {
        value: "Single",
        label: "Single",
      },
      main_m_variants: [
        {
          varient_name: "",
          m_variants: [
            {
              varient_sub_name: "",
              unit: "",
              mrp: "",
              sku: "",
              s_price: "",
              images: [],
            },
          ],
        },
      ],
    },
  });

  const imageFile = watch("image1");
  const imageFile2 = watch("images");

  const {
    fields: groupFields,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: "main_m_variants",
  });

  const handleAddGroup = () => {
    appendGroup({
      varient_name: "",
      m_variants: [
        {
          varient_sub_name: "",
          unit: "",
          mrp: "",
          sku: "",
          s_price: "",
          images: [],
        },
      ],
    });
  };

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();

      DataToSend.append("name", data?.name);

      // if (userdata?.data?.id == 1 && data?.user_id != 1) {
      //   DataToSend.append("user_id", userdata?.data?.id);
      // }
      if (data?.user_id && data?.user_id !== 1) {
        DataToSend.append("user_id", data?.user_id?.value);
      }

      DataToSend.append("short_description", data?.short_description);
      DataToSend.append("manufacturer", data?.manufacturer);
      DataToSend.append("vender", data?.vender);
      DataToSend.append("delivery_charges", data?.delivery_charges);
      if (data?.about) {
        DataToSend.append("about", data?.about);
      }
      if (data?.benifits) {
        DataToSend.append("benifits", data?.benifits);
      }
      if (data?.storage) {
        DataToSend.append("storage", data?.storage);
      }

      DataToSend.append("image1", data?.image1[0]);
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      if (data?.membership_type?.value) {
        DataToSend.append("membership_type", data?.membership_type?.value);
      }

      DataToSend.append("cod_available", data?.cod_available);
      if (data?.membership_type?.value) {
        DataToSend.append(
          "membership_discount",
          data?.membership_type?.value == "Silver"
            ? 0.15
            : data?.membership_type?.value == "Gold"
            ? 0.2
            : 0.3
        );
      }
      DataToSend.append("brand_id", data?.brand_id?.value);
      DataToSend.append("tax_type_id", data?.tax_type_id?.value);
      DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);
      DataToSend.append("type", data?.type?.value);

      const main_m_variants = [];

      data.main_m_variants.forEach((mainVariant, mainIndex) => {
        const m_variants = [];

        mainVariant.m_variants.forEach((variant, variantIndex) => {
          const variantData = {
            varient_sub_name: variant?.varient_sub_name,
            unit: variant?.unit,
            mrp: variant?.mrp,
            sku: variant?.sku,
            trade: variant?.trade,
            scheme: variant?.scheme,
            additional_discount: variant?.additional_discount,
            stock_limit: variant?.stock_limit,
            s_price: variant?.s_price,
            weight_or_piece: variant?.weight_or_piece?.value,
            weight: variant?.weight,
            piece: variant?.piece,
            discount: variant?.discount?.value,
            rupees: variant?.rupees,
            percent: variant?.percent,
            cost_to_boomerbucks: variant?.cost_to_boomerbucks,
            image_status: imageStatus,
            image_change: imageChange,
            product_varient_id: variant?.product_varient_id,
            rupees_or_percent: variant?.rupees_or_percent?.value,
            images: [],
            profit: variant?.profit,
          };

          for (let i = 0; variant?.image[i]; i++) {
            DataToSend.append(
              `images${mainIndex}${variantIndex}${i}`,
              variant?.image[i]
            );
            variantData.images.push(variant?.image[i]?.name);
          }

          m_variants.push(variantData);
        });

        const mainVariantData = {
          varient_name: mainVariant.varient_name,
          m_variants: m_variants,
        };

        main_m_variants.push(mainVariantData);
      });

      DataToSend.append("main_m_variants", JSON.stringify(main_m_variants));

      const response = await postData(`/product/${id}`, DataToSend);

      if (response?.success) {
        await setShowModal({ code: response?.code, message: response.message });
      } else {
        await setShowModal({ code: response?.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  const handleDeleteImage = async (id, type) => {
    const data = {
      type: type,
    };

    const res = await postData(`/product/delete-images/${id}`, data);
    if (res?.success) {
      GetEditData();
      alert("image delete successfully");
    }
  };

  useEffect(() => {
    setWeightOrPieceStates(weightOrPiece);
  }, [weightOrPiece]);

  useEffect(() => {
    setRupeesOrPercentStates(rupeesOrPercent);
  }, [rupeesOrPercent]);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Product</Card.Title>
              <hr />
              <Container>
                <Form>
                  <Row>
                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Category</Form.Label>
                          <Controller
                            name="category_id"
                            {...register("category_id", {
                              required: "Select Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={categories}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  GetAllSubCategory(selectedOption.value);
                                  setValue("category_id", selectedOption);
                                  setValue("sub_category_id", "");
                                  setValue("child_sub_category_id", "");
                                }}
                              />
                            )}
                          />
                          {errors.category_id && (
                            <span className="text-danger">
                              {errors.category_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Sub Category</Form.Label>
                          <Controller
                            name="sub_category_id"
                            {...register("sub_category_id", {
                              required: "Select Sub Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.sub_category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={subCategories}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  GetAllChildSubCategory(selectedOption.value);
                                  setValue("sub_category_id", selectedOption);
                                  setValue("child_sub_category_id", "");
                                }}
                              />
                            )}
                          />
                          {errors.sub_category_id && (
                            <span className="text-danger">
                              {errors.sub_category_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Name</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name .."
                                className={classNames("", {
                                  "is-invalid": errors?.name,
                                })}
                                {...register("name", {
                                  required: "Name is required",
                                  maxLength: {
                                    value: maxCharLimit,
                                    message: `Name cannot exceed ${maxCharLimit} characters`,
                                  },
                                })}
                                maxLength={maxCharLimit}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                            <div className="text-end">
                              <small>{maxCharLimit} characters Limit </small>
                            </div>
                            {errors.name && (
                              <span className="text-danger">
                                {errors.name.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Short Description</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="short_description"
                                placeholder="Short Description"
                                maxLength={40}
                                className={classNames("", {
                                  "is-invalid": errors?.short_description,
                                })}
                                {...register("short_description", {
                                  required: "Short Description is required",
                                  maxLength: {
                                    value: maxCharLimit,
                                    message: `Name cannot exceed ${maxCharLimit} characters`,
                                  },
                                })}
                                maxLength={maxCharLimit}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                            <div className="text-end">
                              <small>{maxCharLimit} characters Limit </small>
                            </div>
                            {errors.short_description && (
                              <span className="text-danger">
                                {errors.short_description.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Form.Label>Image</Form.Label>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.image1,
                            })}
                            type="file"
                            {...register("image1", {
                              // required: "images is required",
                            })}
                            accept="image/*"
                            // disabled={usertype === "Admin"}
                          />
                        </Form.Group>
                        {errors.image1 && (
                          <span className="text-danger">
                            {errors.image1.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col lg={3}>
                      <div className="main-form-section mt-3">
                        <Form.Label>Product Image</Form.Label>

                        {typeof getValues("image1") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("image1")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </Col>

                    {usertype == "Admin" && (
                      <Col lg={3} md={6}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Seller</Form.Label>
                            <Controller
                              name="user_id"
                              {...register("user_id", {
                                required: "Seller Is Required",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.user_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={seller}
                                />
                              )}
                            />
                            {errors.user_id && (
                              <span className="text-danger">
                                {errors.user_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                    )}

                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Varient Type</Form.Label>
                          <Controller
                            name="type"
                            control={control}
                            rules={{ required: "Select Brand" }}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.type
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                                //     isDisabled={usertype === "Admin"}
                                {...field}
                                options={type}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  setValue("type", selectedOption);
                                }}
                              />
                            )}
                          />
                          {errors.type && (
                            <span className="text-danger">
                              {errors.type.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Membership Type</Form.Label>
                          <Controller
                            name="membership_type"
                            control={control}
                            // rules={{ required: "Select Brand" }}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.membership_type
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                                {...field}
                                options={membershipType}
                                onChange={(selectedOption) => {
                                  console.log(selectedOption, "selectedOption");

                                  field.onChange(selectedOption.value);
                                  setValue("membership_type", selectedOption);
                                }}
                              />
                            )}
                          />
                          {errors.membership_type && (
                            <span className="text-danger">
                              {errors.membership_type.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Cod Available</Form.Label>

                          <Controller
                            name="cod_available"
                            control={control}
                            defaultValue={false} // Set the default value to false
                            render={({ field }) => (
                              <div className="form-check">
                                <input
                                  {...field}
                                  type="checkbox"
                                  id="codAvailable"
                                  className="form-check-input"
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    setValue("cod_available", e.target.checked);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="codAvailable"
                                >
                                  Cod Available
                                </label>
                              </div>
                            )}
                          />

                          {errors.cod_available && (
                            <span className="text-danger">
                              {errors.cod_available.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Delivery Charges</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="delivery_charges"
                                placeholder="Delivery Charges .."
                                className={classNames("", {
                                  "is-invalid": errors?.delivery_charges,
                                })}
                                {...register("delivery_charges", {
                                  required: "Delivery Charges is required",
                                  validate: (value) =>
                                    !isNaN(value) ||
                                    "Only numeric values are allowed",
                                })}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Allow only digits
                                }}
                              />
                            </InputGroup>{" "}
                            {errors.delivery_charges && (
                              <span className="text-danger">
                                {errors.delivery_charges.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <div className="main-form-section mt-5"></div>
                    <Card.Title>Product Details</Card.Title>
                    <hr />
                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Manufacturer</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="manufacturer"
                                placeholder="Manufacturer"
                                className={classNames("", {
                                  "is-invalid": errors?.manufacturer,
                                })}
                                {...register("manufacturer", {
                                  required: "Manufacturer is required",
                                  maxLength: {
                                    value: maxCharLimit,
                                    message: `Manufacturer cannot exceed ${maxCharLimit} characters`,
                                  },
                                })}
                                maxLength={maxCharLimit}
                                onChange={handleInputChange}
                                // disabled={usertype === "Admin"}
                              />
                            </InputGroup>
                            <div className="text-end">
                              <small>{maxCharLimit} characters Limit </small>
                            </div>
                            {errors.manufacturer && (
                              <span className="text-danger">
                                {errors.manufacturer.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="justify-content-center">
                          <Form.Label>Vendor</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="vender"
                                placeholder="Vendor"
                                className={classNames("", {
                                  "is-invalid": errors?.vender,
                                })}
                                {...register("vender", {
                                  required: "Vendor is required",
                                  maxLength: {
                                    value: maxCharLimit,
                                    message: `Name cannot exceed ${maxCharLimit} characters`,
                                  },
                                })}
                                maxLength={maxCharLimit}
                                onChange={handleInputChange}
                                // disabled={usertype === "Admin"}
                              />
                            </InputGroup>{" "}
                            <div className="text-end">
                              <small>{maxCharLimit} characters Limit </small>
                            </div>
                            {errors.vender && (
                              <span className="text-danger">
                                {errors.vender.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Brand</Form.Label>
                          <Controller
                            name="brand_id"
                            {...register("brand_id", {
                              required: "Select Brand",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.brand_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                //     isDisabled={usertype === "Admin"}
                                {...field}
                                options={brands}
                              />
                            )}
                          />
                          {errors.brand_id && (
                            <span className="text-danger">
                              {errors.brand_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Tax Type</Form.Label>
                          <Controller
                            name="tax_type_id"
                            {...register("tax_type_id", {
                              required: "Select Tax Type",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.tax_type_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                //     isDisabled={usertype === "Admin"}
                                {...field}
                                options={taxTypes}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  GetAllTaxPercentages(selectedOption.value);
                                  setValue("tax_type_id", selectedOption);
                                }}
                              />
                            )}
                          />
                          {errors.tax_type_id && (
                            <span className="text-danger">
                              {errors.tax_type_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Tax Percentage</Form.Label>
                          <Controller
                            name="tax_percentage_id"
                            {...register("tax_percentage_id", {
                              required: "Select Tax Percentage",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.tax_percentage_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                //     isDisabled={usertype === "Admin"}
                                {...field}
                                options={taxPercentages}
                              />
                            )}
                          />
                          {errors.tax_percentage_id && (
                            <span className="text-danger">
                              {errors.tax_percentage_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          <Col sm={12}>
                            <Form.Label className="text-center">
                              About
                            </Form.Label>
                            <Form.Group>
                              <Controller
                                name="about"
                                control={control}
                                rules={{ required: "About is required" }}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={field.value}
                                    onChange={(newContent) =>
                                      field.onChange(newContent)
                                    }
                                    onBlur={field.onBlur}
                                    className={classNames("", {
                                      "is-invalid": !!errors.about,
                                    })}
                                    placeholder="About"
                                    // config={{
                                    //   readonly: usertype === "Admin",
                                    // }}
                                  />
                                )}
                              />
                            </Form.Group>
                            {errors.about && (
                              <span className="text-danger">
                                {errors.about.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          <Col sm={12}>
                            <Form.Label className="text-center">
                              Benefits
                            </Form.Label>
                            <Form.Group>
                              <Controller
                                name="benifits"
                                control={control}
                                rules={{ required: "Benefits is required" }}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={field.value}
                                    onChange={(newContent) =>
                                      field.onChange(newContent)
                                    }
                                    onBlur={field.onBlur}
                                    className={classNames("", {
                                      "is-invalid": !!errors.benifits,
                                    })}
                                    placeholder="Benefits"
                                    // config={{
                                    //   readonly: usertype === "Admin",
                                    // }}
                                  />
                                )}
                              />
                            </Form.Group>
                            {errors.benifits && (
                              <span className="text-danger">
                                {errors.benifits.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          <Col sm={12}>
                            <Form.Label className="text-center">
                              Storage
                            </Form.Label>
                            <Form.Group>
                              <Controller
                                name="storage"
                                control={control}
                                rules={{ required: "Storage is required" }}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={field.value}
                                    onChange={(newContent) =>
                                      field.onChange(newContent)
                                    }
                                    onBlur={field.onBlur}
                                    className={classNames("", {
                                      "is-invalid": !!errors.storage,
                                    })}
                                    placeholder="Storage"
                                    // config={{
                                    //   readonly: usertype === "Admin",
                                    // }}
                                  />
                                )}
                              />
                            </Form.Group>
                            {errors.storage && (
                              <span className="text-danger">
                                {errors.storage.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <div className="main-form-section mt-5"></div>
                    <Card.Title>
                      Product Variants Details
                      {/* {variantsError && (
                          <div className="text-danger">{variantsError}</div>
                        )} */}
                    </Card.Title>
                    <hr />
                    {groupFields.map((variant, groupIndex) => (
                      <>
                        <div
                          key={groupIndex}
                          className="main-form-section mt-3"
                        >
                          <Row>
                            {getValues("type")?.value == "Single" ? (
                              ""
                            ) : (
                              <Col lg={3} md={6}>
                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label>Variant Name</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name={`main_m_variants.${groupIndex}.varient_name`}
                                          placeholder="Variant Name"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.main_m_variants?.[
                                                groupIndex
                                              ]?.varient_name,
                                          })}
                                          // disabled={usertype === "Admin"}
                                          {...register(
                                            `main_m_variants.${groupIndex}.varient_name`,
                                            {
                                              required:
                                                "Variant name is required",
                                              maxLength: {
                                                value: maxCharLimit,
                                                message: `Name cannot exceed ${maxCharLimit} characters`,
                                              },
                                            }
                                          )}
                                          maxLength={maxCharLimit}
                                          onChange={handleInputChange}
                                        />
                                      </InputGroup>{" "}
                                      <div className="text-end">
                                        <small>
                                          {maxCharLimit} characters Limit{" "}
                                        </small>
                                      </div>
                                      {errors?.main_m_variants?.[groupIndex]
                                        ?.varient_name && (
                                        <span className="text-danger">
                                          {
                                            errors.main_m_variants[groupIndex]
                                              .varient_name.message
                                          }
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            )}
                            <FieldArray
                              control={control}
                              groupIndex={groupIndex}
                              register={register}
                              errors={errors}
                              getValues={getValues}
                              imageFile2={imageFile2}
                              watch={watch}
                              IMG_URL={IMG_URL}
                              setValue={setValue}
                              usertype={usertype}
                              setImageStatus={setImageStatus}
                              imageStatus={imageStatus}
                              handleInputChange={handleInputChange}
                              maxCharLimit={maxCharLimit}
                              setWeightOrPiece={setWeightOrPiece}
                              rupeesOrPercent={rupeesOrPercent}
                              setRupeesOrPercent={setRupeesOrPercent}
                              weightOrPiece={weightOrPiece}
                              weight_or_piece={weight_or_piece}
                              percent_or_rupees={percent_or_rupees}
                              setImageChange={setImageChange}
                              handleDeleteImage={handleDeleteImage}
                              rupeesOrPercentStates={rupeesOrPercentStates}
                              setRupeesOrPercentStates={
                                setRupeesOrPercentStates
                              }
                              weightOrPieceStates={weightOrPieceStates}
                              setWeightOrPieceStates={setWeightOrPieceStates}
                            />

                            {getValues("type")?.value == "Single" ||
                            usertype == "Admin" ? (
                              ""
                            ) : (
                              <Col lg={3} md={6}>
                                <button
                                  type="button"
                                  className="mt-3 add-varient"
                                  onClick={() => removeGroup(groupIndex)}
                                >
                                  Remove Group
                                </button>
                              </Col>
                            )}
                          </Row>
                        </div>
                        {/* <div key={group.id} style={{ marginBottom: "20px" }}>
                            <label>
                              Group Name:
                              <input
                                {...register(`variants.${groupIndex}.name`)}
                              />
                            </label>
                            <FieldArray
                              control={control}
                              groupIndex={groupIndex}
                              register={register}
                            />
                            <button
                              type="button"
                              onClick={() => removeGroup(groupIndex)}
                            >
                              Remove Group
                            </button>
                          </div> */}
                      </>
                    ))}
                    {getValues("type")?.value == "Single" ||
                    usertype == "Admin" ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="mt-3 add-varient"
                        onClick={() => handleAddGroup()}
                      >
                        Add Group
                      </button>
                    )}
                  </Row>
                  {/* {usertype !== "Admin" && ( */}
                  <Row className="mt-5 pb-3">
                    <div className="d-flex justify-content-center">
                      <Link>
                        <CancelButton
                          name={"cancel"}
                          handleClose={props.handleClose}
                        />
                      </Link>

                      <Button
                        name={"save"}
                        onClick={handleSubmit(onSubmit)}
                        type="button"
                        className="save-btnnnnnn"
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-floppy-disk"
                          className="me-2"
                        />
                        Save
                      </Button>
                    </div>
                  </Row>
                  {/* )} */}
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

const FieldArray = ({
  control,
  groupIndex,
  register,
  errors,
  getValues,
  watch,
  IMG_URL,
  setValue,
  usertype,
  setImageStatus,
  imageStatus,
  handleInputChange,
  maxCharLimit,
  weight_or_piece,
  weightOrPiece,
  setWeightOrPiece,
  setRupeesOrPercent,
  rupeesOrPercent,
  percent_or_rupees,
  setImageChange,
  handleDeleteImage,
  rupeesOrPercentStates,
  setRupeesOrPercentStates,
  weightOrPieceStates,
  setWeightOrPieceStates,
}) => {
  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: `main_m_variants.${groupIndex}.m_variants`,
  });

  console.log(weightOrPiece, "weightOrPiece");

  const handleAddItem = () => {
    appendItem({ value: "", m_variant_images: [] });
  };

  const handleMRPChange = (itemIndex, newMRP) => {
    const trade = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade`
      ) || 0
    );

    const scheme = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme`
      ) || 0
    );

    const additional_discount = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.additional_discount`
      ) || 0
    );

    // Calculate the scheme price as MRP - Scheme Percent
    const tradePrice = newMRP / trade;
    const schemePrice = tradePrice - tradePrice * (scheme / 100);
    const costToBoomerbucks = schemePrice - additional_discount;
    // const sellingPrice = newMRP - discount;

    // Update the scheme price and selling price in the form
    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade_price`,
      tradePrice.toFixed(2)
    );

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme_price`,
      schemePrice.toFixed(2)
    );

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.cost_to_boomerbucks`,
      costToBoomerbucks.toFixed(2)
    );
  };

  const handleWebsiteMRPChange = (itemIndex, newMRP) => {
    const rupees = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.rupees`
      ) || 0
    );

    const percent = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.percent`
      ) || 0
    );

    console.log({ percent, rupees, newMRP }, "Input Values");

    if (isNaN(newMRP) || newMRP <= 0) {
      console.error("Invalid MRP value");
      return;
    }

    const selling_price =
      rupees > 0
        ? newMRP - rupees
        : percent > 0
        ? newMRP - (newMRP * percent) / 100
        : newMRP;

    console.log(selling_price, "Calculated Selling Price");

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`,
      selling_price.toFixed(2)
    );

    //////////////////////////////////////////////////////////////////////Boomerbucks Profit/////////////////////////////////////

    const trade = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade`
      ) || 0
    );

    const scheme = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme`
      ) || 0
    );

    const additional_discount = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.additional_discount`
      ) || 0
    );

    // Calculate the scheme price as MRP - Scheme Percent
    const tradePrice = newMRP / trade;
    const schemePrice = tradePrice - tradePrice * (scheme / 100);
    const costToBoomerbucks = schemePrice - additional_discount;

    const BoomerbucksProfit = selling_price - costToBoomerbucks;

    console.log(BoomerbucksProfit, "BoomerbucksProfit");

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.profit`,
      BoomerbucksProfit.toFixed(2)
    );
  };

  // Watch the fields
  const watchItems = watch(
    `main_m_variants.${groupIndex}.m_variants`,
    itemFields
  );

  useEffect(() => {
    watchItems.forEach((item, itemIndex) => {
      const mrp = parseFloat(item.mrp || 0);
      handleMRPChange(itemIndex, mrp);
      handleWebsiteMRPChange(itemIndex, mrp);
    });
  }, [watchItems, groupIndex, setValue]);

  const handleWeightOrPieceChange = (itemIndex, selectedOption) => {
    const newWeightOrPieceStates = [...weightOrPieceStates];
    newWeightOrPieceStates[itemIndex] = selectedOption.value;
    setWeightOrPieceStates(newWeightOrPieceStates);
    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.weight_or_piece`,
      selectedOption
    );
  };

  const handleRupeesOrPercentChange = (itemIndex, selectedOption) => {
    const newRupeesOrPercentStates = [...rupeesOrPercentStates];
    newRupeesOrPercentStates[itemIndex] = selectedOption.value;
    setRupeesOrPercentStates(newRupeesOrPercentStates);
    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`,
      selectedOption
    );
  };

  return (
    <>
      {itemFields.map((item, itemIndex) => (
        <div key={item.id} className="main-form-section mt-3">
          <Row>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>
                    {" "}
                    {getValues("type")?.value == "Single" ? "" : "Sub"} Variant
                    Name
                  </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.varient_sub_name`}
                        placeholder={
                          (getValues("type")?.value == "Single" ? "" : "Sub ") +
                          "Variant Name"
                        }
                        className={classNames("", {
                          "is-invalid":
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.varient_sub_name,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.varient_sub_name`,
                          {
                            required: "Sub Variant name is required",
                            maxLength: {
                              value: maxCharLimit,
                              message: `Name cannot exceed ${maxCharLimit} characters`,
                            },
                          }
                        )}
                        maxLength={maxCharLimit}
                        onChange={handleInputChange}
                      />
                    </InputGroup>{" "}
                    <div className="text-end">
                      <small>{maxCharLimit} characters Limit </small>
                    </div>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.varient_sub_name && (
                      <span className="text-danger">
                        {
                          errors.variants?.[groupIndex]?.m_variants?.[itemIndex]
                            ?.varient_sub_name?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Stock Unit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`}
                        placeholder="Stock Unit"
                        className={classNames("", {
                          "is-invalid":
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.unit,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`,
                          {
                            required: "Stock Unit is required",
                            validate: (value) =>
                              value >= 0 || "Stock Unit cannot be negative",
                          }
                        )}
                        onInput={(e) => {
                          // Allow only digits and one decimal point, and restrict to 99
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                            .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                          // Restrict input to values less than or equal to 99
                        }}
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.unit && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.unit.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>{" "}
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>MRP</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="MRP"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.mrp,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`,
                          {
                            required: "MRP is required",
                            validate: (value) =>
                              value >= 0 || "MRP cannot be negative",
                            onChange: (e) => {
                              const newMRP = parseFloat(e.target.value || 0);
                              handleMRPChange(itemIndex, newMRP);
                              handleWebsiteMRPChange(itemIndex, newMRP);
                            },
                          }
                        )}
                        onInput={(e) => {
                          // Allow only digits and one decimal point, and restrict to 99
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                            .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                          // Restrict input to values less than or equal to 99
                        }}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.mrp && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.mrp?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>{" "}
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>SKU</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.sku`}
                        placeholder="SKU"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.sku,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.sku`,
                          {
                            required: "SKU is required",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.sku && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.sku.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Weight Or Piece</Form.Label>

                  <Controller
                    name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.weight_or_piece`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={weight_or_piece}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors?.main_m_variants?.[groupIndex]
                              ?.m_variants?.[itemIndex]?.weight_or_piece
                              ? "red"
                              : baseStyles.borderColor,
                          }),
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleWeightOrPieceChange(itemIndex, selectedOption);
                        }}
                      />
                    )}
                  />

                  {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                    itemIndex
                  ]?.weight_or_piece && (
                    <span className="text-danger">
                      {
                        errors.main_m_variants?.[groupIndex]?.m_variants?.[
                          itemIndex
                        ]?.weight_or_piece.message
                      }
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            {weightOrPieceStates[itemIndex] == "piece" ? (
              <Col lg={3} md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Piece</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.piece`}
                          placeholder="Piece"
                          className={classNames("", {
                            "is-invalid":
                              errors?.main_m_variants?.[groupIndex]
                                ?.m_variants?.[itemIndex]?.piece,
                          })}
                          {...register(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.piece`,
                            {
                              required: "Piece is required",
                              validate: (value) =>
                                value >= 0 || "Piece cannot be negative",
                            }
                          )}
                          onInput={(e) => {
                            // Allow only digits and one decimal point, and restrict to 99
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                              .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                            // Restrict input to values less than or equal to 99
                          }}
                        />
                      </InputGroup>
                      {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                        itemIndex
                      ]?.piece && (
                        <span className="text-danger">
                          {
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.piece.message
                          }
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
            ) : (
              <Col lg={3} md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Weight (In grams)</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.weight`}
                          placeholder="weight"
                          className={classNames("", {
                            "is-invalid":
                              errors?.main_m_variants?.[groupIndex]
                                ?.m_variants?.[itemIndex]?.weight,
                          })}
                          {...register(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.weight`,
                            {
                              required: "Weight is required",
                              validate: (value) =>
                                value >= 0 || "Weight cannot be negative",
                            }
                          )}
                          onInput={(e) => {
                            // Allow only digits and one decimal point, and restrict to 99
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                              .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                            // Restrict input to values less than or equal to 99
                          }}
                        />
                      </InputGroup>
                      {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                        itemIndex
                      ]?.weight && (
                        <span className="text-danger">
                          {
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.weight.message
                          }
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
            )}
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Stock limit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.stock_limit`}
                        placeholder="Stock limit"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.stock_limit,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.stock_limit`,
                          {
                            required: "Stock Limit is required",
                            validate: (value) =>
                              value >= 0 || "Stock Unit cannot be negative",
                          }
                        )}
                        onInput={(e) => {
                          // Allow only digits and one decimal point, and restrict to 99
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                            .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                          // Restrict input to values less than or equal to 99
                        }}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.stock_limit && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.stock_limit.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>{" "}
            <Col lg={3}>
              <div className="main-form-section mt-3">
                <Form.Label>images</Form.Label>

                <Form.Group>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid":
                        errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                          itemIndex
                        ]?.image,
                    })}
                    type="file"
                    multiple
                    {...register(
                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.image`
                      // {
                      //   required: "images is required",
                      // }
                    )}
                    accept="image/*"
                    onChange={() => setImageChange(1)}
                  />
                </Form.Group>
                {errors?.main_m_variants?.[groupIndex]?.m_variants?.[itemIndex]
                  ?.image && (
                  <span className="text-danger">
                    {
                      errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                        itemIndex
                      ]?.image.message
                    }
                  </span>
                )}
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Form.Label>Image Preview</Form.Label>
                <div className="image-preview-container">
                  {Array.isArray(
                    watch(
                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.m_variant_images`
                    )
                  ) &&
                    watch(
                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.m_variant_images`
                    )?.map((image, index) => (
                      <div
                        key={index}
                        className="image-container"
                        style={{
                          position: "relative",
                          display: "inline-block",
                          margin: "5px",
                        }}
                      >
                        <img
                          src={`${IMG_URL}${image?.image}`}
                          alt={`Preview ${index}`}
                          className="image-preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <button
                          type="button"
                          className="delete-icon"
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            background: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDeleteImage(
                              image?.id,
                              getValues("type.label")
                            )
                          }
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
            <h2 className="form_divider_text">To Boomerbucks</h2>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Trade Percent</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Scheme Percent"
                        max={100}
                        defaultValue={0}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.trade,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade`,
                          {
                            required: "Trade Percent is required",
                            validate: {
                              minValue: (value) =>
                                value >= 0 ||
                                "Trade Percent cannot be Negative",
                              maxValue: (value) =>
                                value <= 100 ||
                                "Trade Percent cannot exceed 100",
                            },
                            onChange: (e) => {
                              const newScheme = parseFloat(e.target.value || 0);
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                        onInput={(e) => {
                          // Allow only digits and one decimal point, and restrict to 99
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                            .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                          // Restrict input to values less than or equal to 99
                        }}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.trade && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.trade?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Scheme Percent</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Scheme Percent"
                        max={100}
                        defaultValue={0}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.scheme,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme`,
                          {
                            required: "Scheme Percent is required",
                            validate: (value) =>
                              value >= 0 || "Scheme Percent cannot be negative",
                            validate: {
                              minValue: (value) =>
                                value >= 0 ||
                                "Scheme Percent cannot be Negative",
                              maxValue: (value) =>
                                value <= 100 ||
                                "Scheme Percent cannot exceed 100",
                            },
                            onChange: (e) => {
                              const newScheme = parseFloat(e.target.value || 0);
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                        onInput={(e) => {
                          // Allow only digits and one decimal point, and restrict to 99
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                            .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                          // Restrict input to values less than or equal to 99
                        }}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.scheme && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.scheme?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Additional Discount In Rupees </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.additional_discount`}
                        placeholder="Additional Discount "
                        defaultValue={0}
                        max={100}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.additional_discount,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.additional_discount`,
                          {
                            required: "Discount   is required",
                            validate: (value) =>
                              value >= 0 || "Discount  cannot be negative",

                            onChange: (e) => {
                              const newDiscount = parseFloat(
                                e.target.value || 0
                              );
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                        onInput={(e) => {
                          // Allow only digits and one decimal point, and restrict to 99
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                            .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                          // Restrict input to values less than or equal to 99
                        }}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.additional_discount && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.additional_discount?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Trade Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Trade Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.trade_price,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade_price`,
                          {
                            required: "Trade Price is required",
                            validate: (value) =>
                              value >= 0 || "Trade Price cannot be negative",
                            validate: (value) => {
                              return true;
                            },
                          }
                        )}
                        readOnly
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.trade_price && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.trade_price?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Scheme Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Selling Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.scheme_price,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme_price`,
                          {
                            required: "Scheme Price is required",
                            validate: (value) => {
                              return true;
                            },
                          }
                        )}
                        readOnly
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.scheme_price && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.scheme_price?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Cost To Boomerbucks</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.cost_to_boomerbucks`}
                        placeholder="Cost To Boomerbucks"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.cost_to_boomerbucks,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.cost_to_boomerbucks`,
                          {
                            required: "Cost To Boomerbucks is required",
                            validate: (value) =>
                              value >= 0 || "Stock Unit cannot be negative",
                          }
                        )}
                        onInput={(e) => {
                          // Allow only digits and one decimal point, and restrict to 99
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                            .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                          // Restrict input to values less than or equal to 99
                        }}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.cost_to_boomerbucks && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.cost_to_boomerbucks.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <h2 className="form_divider_text">On Website</h2>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>MRP Discount</Form.Label>

                  <Controller
                    name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`}
                    control={control}
                    // defaultValue={percent_or_rupees[0]} // Set the default value here
                    // rules={{ required: "Weight or Piece is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={percent_or_rupees}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors?.main_m_variants?.[groupIndex]
                              ?.m_variants?.[itemIndex]?.discount
                              ? "red"
                              : baseStyles.borderColor,
                          }),
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleRupeesOrPercentChange(
                            itemIndex,
                            selectedOption
                          );
                          setValue(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.rupees`,
                            null
                          );

                          setValue(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.percent`,
                            null
                          );

                          setValue(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`,
                            null
                          );
                        }}
                      />
                    )}
                  />

                  {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                    itemIndex
                  ]?.weight_or_piece && (
                    <span className="text-danger">
                      {
                        errors.main_m_variants?.[groupIndex]?.m_variants?.[
                          itemIndex
                        ]?.weight_or_piece.message
                      }
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            {rupeesOrPercentStates[itemIndex] == "rupees" ? (
              <Col lg={3} md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Rupees</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.rupees`}
                          placeholder="Rupees"
                          className={classNames("", {
                            "is-invalid":
                              errors?.main_m_variants?.[groupIndex]
                                ?.m_variants?.[itemIndex]?.rupees,
                          })}
                          {...register(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.rupees`,
                            {
                              required: "Rupees is required",
                              validate: {
                                nonNegative: (value) =>
                                  value >= 0 || "Rupees cannot be negative",
                                withinMRP: (value) => {
                                  const mrp = parseFloat(
                                    getValues(
                                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                    ) || 0
                                  );
                                  return (
                                    parseFloat(value) <= mrp ||
                                    "Rupees cannot exceed MRP"
                                  );
                                },
                              },
                              onChange: (e) => {
                                const newValue = parseFloat(
                                  e.target.value || 0
                                );
                                const mrp = parseFloat(
                                  getValues(
                                    `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                  ) || 0
                                );
                                handleWebsiteMRPChange(itemIndex, mrp);
                              },
                            }
                          )}
                          onInput={(e) => {
                            // Allow only digits and one decimal point, and restrict to 99
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                              .replace(/(\..*)\./g, "$1"); // Allow only one decimal point
                          }}
                        />
                      </InputGroup>
                      {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                        itemIndex
                      ]?.rupees && (
                        <span className="text-danger">
                          {
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.rupees.message
                          }
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
            ) : (
              <Col lg={3} md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Percent %</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.percent`}
                          placeholder="Percent"
                          className={classNames("", {
                            "is-invalid":
                              errors?.main_m_variants?.[groupIndex]
                                ?.m_variants?.[itemIndex]?.percent,
                          })}
                          {...register(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.percent`,
                            {
                              required: "Percent is required",
                              validate: {
                                positive: (value) =>
                                  value >= 0 || "Percent cannot be negative",
                                max: (value) =>
                                  value <= 100 || "Percent cannot exceed 100",
                              },
                              onChange: (e) => {
                                const newDiscount = parseFloat(
                                  e.target.value || 0
                                );
                                const percent = parseFloat(
                                  getValues(
                                    `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                  ) || 0
                                );
                                handleWebsiteMRPChange(itemIndex, percent);
                              },
                            }
                          )}
                          onInput={(e) => {
                            // Allow only digits and one decimal point
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                              .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                            // Restrict input to values less than or equal to 100
                            if (parseFloat(e.target.value) > 100) {
                              e.target.value = "100";
                            }
                          }}
                        />
                      </InputGroup>
                      {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                        itemIndex
                      ]?.percent && (
                        <span className="text-danger">
                          {
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.percent.message
                          }
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
            )}
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Selling Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`}
                        placeholder="Selling Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.s_price,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`,
                          {
                            required: "Selling Price is required",
                            validate: (value) => {
                              if (!value) return "Selling Price is required";
                              const minimumOrderValue = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                )
                              );
                              if (parseFloat(value) <= 0) {
                                return "Selling Price must be a positive number";
                              }

                              // Check if discount is less than minimum order value
                              if (parseFloat(value) > minimumOrderValue) {
                                return "Selling Price must not be greater than MRP";
                              }

                              return true; // Return true for valid input
                            },
                          }
                        )}
                        readOnly
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.s_price && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.s_price.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Boomerbucks Profit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.profit`}
                        placeholder="Selling Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.profit,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.profit`,
                          {
                            required: "Selling Price is required",
                            validate: (value) => {
                              if (!value) return "Selling Price is required";
                              const minimumOrderValue = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                )
                              );
                              if (parseFloat(value) <= 0) {
                                return "Profit must be a positive number";
                              }

                              // Check if discount is less than minimum order value
                              if (parseFloat(value) > minimumOrderValue) {
                                return "Profit must not be greater than MRP";
                              }

                              return true; // Return true for valid input
                            },
                          }
                        )}
                        readOnly
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.profit && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.profit.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <br />
            <hr />
            <hr />
            <hr />
            {usertype !== "Admin" && (
              <Col lg={3}>
                <div className="main-form-section mt-3">
                  {/* <Form.Label></Form.Label> */}

                  <Form.Group>
                    <button
                      type="button"
                      className="mt-3 add-varient"
                      onClick={() => removeItem(itemIndex)}
                    >
                      Remove Item
                    </button>
                  </Form.Group>
                </div>
              </Col>
            )}
          </Row>
        </div>
      ))}
      {usertype !== "Admin" && (
        <Col lg={3} md={6}>
          <button
            type="button"
            className="mt-3 add-varient"
            onClick={handleAddItem}
          >
            Add Item
          </button>
        </Col>
      )}
    </>
  );
};

export default EditOffCanvance;
