import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";
import AllProductColumnChart from "../Garph/AllProduct-PieChart/Product_pie_chart";
import PieChart from "../Garph/Seller_pie_chart/Seller_pie_chart";
import SellerProduct_pie_chart from "../Garph/SellerProductPieChart/SellerProduct_pie_chart";

import { formatDate, timeShow } from "../../../utils/common";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    getDownloadDataExcel,
    Per_Page_Dropdown,
    SelectUsers,
    SelectReportAnalysisData,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [daysAgo, setDaysAgo] = useState("");

  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [searchDate, setSearchDate] = useState("");

  const [sellerReset, setSellerReset] = useState();
  const [selectedReport, setSelectedReport] = useState(null);

  const getDataAll = async () => {
    const response = await getData(
      `/product-graph?page=${currentPage}&per_page=${perPage}&term=${search}&daysAgo=${daysAgo}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    // setSearch(response?.data?.search_name);
    // setCurrentPage(response?.data?.);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1, search, searchDate, daysAgo]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/inquire/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/inquire/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/inquire",
          selectAllChecked,
          "Contact us list"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const [reportAnalysis, setReportAnalysis] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const [selectedSellerId, setSelectedSellerId] = useState(null);
  const [productForGraphBySeller, setProductForGraphBySeller] = useState([]);
  const [productGraphCountBySeller, setProductGraphCountBySeller] = useState(
    []
  );
  const [sellers, setSellers] = useState("");
  const [sellerCount, setSellerCount] = useState([]);
  const [productGraph, setProductGraph] = useState("");
  const [productForGraph, setProductForGraph] = useState([]);
  const [productGraphCount, setProductGraphCount] = useState([]);
  const GetAllProductGraph = async (days) => {
    try {
      const res = await getData(`/product-graph`);
      setProductGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetSellers = async (days) => {
    try {
      let url = `/most-popular-seller`;

      if (days) {
        url += `?daysAgo=${days}`;
      }
      const res = await getData(url);
      setSellers(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetSellerGraph = async (days) => {
    try {
      // let url = `/seller-graph`;

      // if (days) {
      //   url += `?daysAgo=${days}`;
      // }
      const res = await getData(`/seller-graph`);
      setSellerGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetAllProductGraphBySeller = async (id, daysAgo) => {
    try {
      const res = await getData(
        `/product-graph-by-seller/${id}?daysAgo=${daysAgo}`
      );
      setProductGraphBySeller(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    GetAllProductGraphBySeller();
  }, [sellerReset]);

  const GetAllOnlySellers = async () => {
    const response = await getData("/all-sellers");
    if (response?.success) {
      const data = await SelectUsers(response?.data);
      setAllSellers(data);
    }
  };

  const GetAllReportAnalysis = async () => {
    const response = await getData("/all-report-analysis");
    if (response?.success) {
      const data = await SelectReportAnalysisData(response?.data);
      setReportAnalysis(data);
    }
  };

  const [productGraphBySeller, setProductGraphBySeller] = useState("");
  const [sellerData, setSellerData] = useState([]);
  const [sellerGraph, setSellerGraph] = useState("");
  const [sellerGraphData, setSellerGraphData] = useState([]);
  const [sellerGraphCount, setSellerGraphCount] = useState([]);

  useEffect(() => {
    const extractedProduct = productGraph?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProductForGraph(extractedProduct);

    const extractedProductCount = productGraph?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductGraphCount(extractedProductCount);
  }, [productGraph]);

  useEffect(() => {
    const extractedSeller = sellers?.top5
      ?.map((value) => value.seller?.first_name)
      .filter((seller) => seller !== undefined);
    setSellerData(extractedSeller);

    const extractedSellerCount = sellers?.top5
      ?.map((value) => value.productCount)
      .filter((count) => count !== undefined);
    setSellerCount(extractedSellerCount);
  }, [sellers]);

  useEffect(() => {
    const extractedSeller = sellerGraph?.top5
      ?.map((value) => value.seller?.first_name)
      .filter((seller) => seller !== undefined);
    setSellerGraphData(extractedSeller);

    const extractedSellerCount = sellerGraph?.top5
      ?.map((value) => value.productCount)
      .filter((count) => count !== undefined);
    setSellerGraphCount(extractedSellerCount);
  }, [sellerGraph]);

  useEffect(() => {
    const extractedProductBySeller = productGraphBySeller?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProductForGraphBySeller(extractedProductBySeller);

    const extractedProductCountBySeller = productGraphBySeller?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductGraphCountBySeller(extractedProductCountBySeller);
  }, [productGraphBySeller]);

  useEffect(() => {
    GetAllProductGraph();
    GetSellerGraph();
    GetSellers();
    GetAllOnlySellers();
    GetAllReportAnalysis();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Report In graph"} link={"/home/ad-banner"} />
        <section className="AdvanceDashboard">
          <div className="row ">
            <div className="col-lg-12">
              <div className="row MainRowsec  justify-content-between">
                <div className=" col-12">
                  <div className="chart-heading">
                    <h1>All Products Graph</h1>
                  </div>
                  {productForGraph?.length > 0 &&
                    productGraphCount?.length > 0 && (
                      <AllProductColumnChart
                        productData={productForGraph}
                        productCount={productGraphCount}
                      />
                    )}
                </div>

                <div className="col-xl-5 col-lg-6 col-md-12 col-12 p-lg-5 p-mb-5">
                  <div className="chart-heading">
                    <h1> All Sellers Report </h1>
                  </div>

                  {sellerGraphData?.length > 0 &&
                    sellerGraphCount?.length > 0 &&
                    sellerData?.length > 0 &&
                    sellerCount?.length > 0 && (
                      <PieChart
                        sellerData={sellerGraphData}
                        sellerCount={sellerGraphCount}
                      />
                    )}
                </div>

                <div className="col-xl-5 col-lg-6 col-md-12 col-12 p-lg-5 p-mb-5">
                  <div className="chart-heading">
                    <h1> Seller Products </h1>
                  </div>

                  <div className="top-selling-product-dropdown-holder">
                    <Select
                      className="mb-3"
                      placeholder={"Seller"}
                      value={
                        selectedSellerId
                          ? allSellers.find(
                              (seller) => seller.value === selectedSellerId
                            )
                          : null
                      }
                      options={allSellers}
                      onChange={(selectedOption) => {
                        setSelectedSellerId(selectedOption?.value);
                        if (selectedOption?.value && selectedReport?.name) {
                          GetAllProductGraphBySeller(
                            selectedOption?.value,
                            selectedReport?.name
                          );
                        }
                      }}
                      // onChange={(selectedOption) => {
                      //   setSelectedSellerId(selectedOption?.value);
                      //   // GetAllProductGraphBySeller(selectedOption?.value);
                      // }}
                    />
                    <Select
                      className="mb-3"
                      placeholder={"Search for Number Of Days"}
                      value={selectedReport}
                      options={reportAnalysis}
                      onChange={(selectedReport) => {
                        setSelectedReport(selectedReport);
                        if (selectedSellerId && selectedReport?.name) {
                          GetAllProductGraphBySeller(
                            selectedSellerId,
                            selectedReport?.name
                          );
                        }
                      }}
                    />
                    <div className="Search-1 ms-auto">
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedSellerId("");
                          setSelectedReport(null);
                          GetAllProductGraphBySeller("");
                          setSellerReset(!sellerReset);
                        }}
                        className="btn btn-danger"
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                  {productForGraphBySeller?.length > 0 &&
                    productGraphCountBySeller?.length > 0 && (
                      <SellerProduct_pie_chart
                        productData={productForGraphBySeller}
                        productCount={productGraphCountBySeller}
                      />
                    )}
                </div>
                {/* ....................................................................................................? */}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
