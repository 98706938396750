import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";
// import CupponCodes from "./Components/Masters/CupponCodes/Tables";

import SellerDetails from "./Components/sellerDetails/Table";

import Role from "./Components/EmployeeManagement/Role/Table";

import EmployeeDetailsRole from "./Components/EmployeeManagement/EmployeeDetails/Tables";
import EmployeeRequest from "./Components/EmployeeManagement/EmployeeRequests/Table";
import EmployeeRejected from "./Components/EmployeeManagement/EmployeeRejected/Table";
import CustomerDetails from "./Components/EmployeeManagement/CustomerDetails/Table";
import ContactUs from "./Components/CustomerManagement/ContactUSList/Tables";
import SubscribeNewsletter from "./Components/CustomerManagement/SubscribeNewsletter/Table";
import CareerApplicationForm from "./Components/CustomerManagement/CareerApplicationForm/Tables";
import Inquire from "./Components/CustomerManagement/Inquire/Tables";

import Product from "./Components/Products/Product/Table";
import ProductRequests from "./Components/Products/ProductRequests/Table";
import ProductReject from "./Components/Products/ProductReject/Table";
import WhyChooseUs from "./Components/AboutUs/WhyChooseUs/Tables";
import HappyCustomers from "./Components/AboutUs/HappyCustomers/Tables";
import OurStory from "./Components/AboutUs/OurStory/Tables";
import AboutBanner from "./Components/AboutUs/AboutBanner/Tables";
import Orders from "./Components/OrderManagments/Orders/Table";
import OrdersToRefund from "./Components/OrderManagments/OrderToRefund/Table";
import ReturnOrders from "./Components/OrderManagments/ReturnOrders/Table";

// Career
import WorkModule from "./Components/Career/WorkModule/Table";
import JobType from "./Components/Career/JobType/Table";
import Department from "./Components/Career/Department/Table";
import Qualification from "./Components/Career/Qualification/Table";
import Jobs from "./Components/Career/Jobs/Tables";

// Settings
// Categories
import Category from "./Components/Masters/Category/Tables";
import SubCategory from "./Components/Masters/Sub_Category/Tables";
import ChildSubCategory from "./Components/Masters/Child_Sub_Category/Tables";
import Attributes from "./Components/Masters/Attribute/Tables";
import SubAttributes from "./Components/Masters/SubAttribute/Tables";
import CategoryForSeller from "./Components/Masters/CategoryForSeller/Tables";
import OrderCancelReasons from "./Components/Masters/OrderCancelReasons/Tables";

// Customization
import WebHeader from "./Components/Masters/WebHeader/Tables";
import AppSetup from "./Components/Masters/AppSetup/Tables";
import Birthday from "./Components/CustomerManagement/Birthday/Tables";
import HomeBanner from "./Components/Masters/S_Banner/Tables";
import HomeAdBanner from "./Components/Masters/Ad_Banner/Tables";
import Blogs from "./Components/Masters/Blogs/Tables";
import BlogVideo from "./Components/Masters/BlogVideo/Table";
import FaqCategory from "./Components/Masters/FaqCategory/Tables";
import Faq from "./Components/Masters/FAQs/Tables";
import CategoryBanner from "./Components/Masters/category_Banner/Tables";
import Privacy_Policy from "./Components/Masters/Privacy_Policy/Table";
import Terms_and_Condition from "./Components/Masters/Terms_and_Condition/Table";

import CouponCodes from "./Components/Masters/CouponCodes/Table";
import Brands from "./Components/Masters/Brands/Tables";

// Location
import Country from "./Components/Masters/Country/Tables";
import State from "./Components/Masters/State/Tables";
import City from "./Components/Masters/City/Tables";
import Pincode from "./Components/Masters/Pincode/Tables";
import CountryCodes from "./Components/Masters/CountryCode/Tables";

// Tax
import TaxType from "./Components/Masters/Tax_Types/Tables";
import TaxPercentage from "./Components/Masters/Tax_Percentage/Tables";

import MembershipDetails from "./Components/Masters/MembershipDetails/Tables";
import Notifications from "./Components/Masters/Notifications/Table";

// DeliveryBoy
import DeliveryBoy from "./Components/DeliveryBoy/Tables";

// Supplier
import Supplier from "./Components/Supplier/Tables";

// Advertisement Banner
import AdvertisementBanner from "./Components/Masters/AdvertisementBanner/Tables";

// Advertisement Banner
import OrderPurchase from "./Components/StockManagement/OrderPurchase/Table";

////////////////////////////// Finance/////////////////////////
import Finance from "./Components/Finance/Tables";

import ProductWiseReport from "./Components/Finance/ProductWiseReport/Table";

import FinanceSubscriptionHistory from "./Components/Finance/FinanceSubscriptionHistory/Tables";

import FinanceProductReport from "./Components/FinanceProductReport/Table";

import FinanceSellerProductReport from "./Components/FinanceSellerProductReport/Table";

import FinanceProfitLoss from "./Components/Finance/FinanceProfitLoss/Table";

import FinanceProductDiscount from "./Components/Finance/FinanceProductDiscount/Table";

import FinanceOrderCouponDiscount from "./Components/Finance/FinanceOrderCouponDiscount/Table";

import FinanceQuickDelivery from "./Components/Finance/FinanceQuickDelivery/Table";

import FinancePayments from "./Components/Finance/FinancePayments/Table";

import FinanceDeliveryCharges from "./Components/Finance/FinanceDeliveryCharges/Table";

// ProductReview
import ProductReview from "./Components/Products/ProductReview/Table";

// Wallet Request
import WithdrawlRequest from "./Components/WithdrawlRequest/Tables";

// wallet historys
import WalletHistory from "./Components/WalletHistory/Tables";

// .........................................................Complaint Query..................................................................................

import TicketComplaintQuery from "./Components/TicketComplaintQuery/Table";

// ......................................................Report Analytics...........................................................................

import ProductSellingReport from "./Components/ReportAnalytics/ProductSellingReport/Table";

import ProductStockReport from "./Components/ReportAnalytics/ProductStockReport/Table";

import OrderReport from "./Components/ReportAnalytics/OrderReport/Table";

import CustomerOrderReport from "./Components/ReportAnalytics/CustomerOrderReport/Table";

import ReportInGraph from "./Components/ReportAnalytics/ReportInGraph/Table";

// ......................................................Report Analytics End...........................................................................

// ......................................................Transaction Data...........................................................................

import SubscriptionHistory from "./Components/TransactionData/SubscriptionHistory/Tables";

import OrderTransactionHistory from "./Components/TransactionData/OrderTransactionHistory/Tables";

import ReturnOrderTransactionHistory from "./Components/TransactionData/ReturnOrderTransactionHistory/Tables";

import WalletTransactionHistory from "./Components/TransactionData/WalletTransactionHistory/Tables";

// ......................................................Transaction Data End...........................................................................

// ......................................................Billing Report...........................................................................

import VendorBillingReport from "./Components/Billing/VendorBillingReport/Table";

import CustomerBillingReport from "./Components/Billing/CustomerBillingReport/Table";

// ......................................................Billing Report End...........................................................................

// ......................................................Utilities Data...........................................................................

// import AcceptedSellerData from "./Components/Utilities/AcceptedSellerData/Table";

import DeliveryBoyTransaction from "./Components/Utilities/DeliveryBoyTransaction/Table";

// ......................................................Utilities Data End...........................................................................

import ReportAnalysis from "./Components/Masters/ReportAnalysis/Tables";

import Features from "./Components/Masters/Features/Tables";
import PageNotFound from "./Components/Masters/PageNotFound/PageNotFound";
import { IDS } from "./utils/common";
const App = () => {
  const location = useLocation();
  const { isSidebarOpen } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);
  const { isAllow } = useContext(Context);
  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/Header",
        element: <Header />,
        status: true,
      },
      {
        path: "/advanceDashboard",
        element: <AdvanceDashboard />,
        status: true,
      },
      {
        path: "/settings/location/country",
        element: <Country />,
        status: IDS.Country.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/location/state",
        element: <State />,
        status: IDS.State.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/location/city",
        element: <City />,
        status: IDS.City.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/location/pincode",
        element: <Pincode />,
        status: IDS.Pincode.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/location/country-codes",
        element: <CountryCodes />,
        status: IDS.CountryCode.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/category",
        element: <Category />,
        status: IDS.Category.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/sub-category",
        element: <SubCategory />,
        status: IDS.SubCategory.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/category-for-seller",
        element: <CategoryForSeller />,
        status: IDS.Category.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/masters/order-cancel-reasons",
        element: <OrderCancelReasons />,
        status: IDS.CancelOrderReasons.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/career/work-module",
        element: <WorkModule />,
        status: IDS.WorkModule.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/career/job-type",
        element: <JobType />,
        status: IDS.JobType.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/career/department",
        element: <Department />,
        status: IDS.Department.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/career/qualification",
        element: <Qualification />,
        status: IDS.Qualification.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/career/jobs",
        element: <Jobs />,
        status: IDS.Jobs.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/order-managements/order/:id",
        element: <Orders />,
        status: IDS.Order.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/order-managements/order/return",
        element: <ReturnOrders />,
        status: IDS.OrderToRefund.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/product",
        element: <Product />,
        status: IDS.Product.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/product/:id",
        element: <Product />,
        status: IDS.Product.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/product-requests",
        element: <ProductRequests />,
        status: IDS.ProductRequest.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/product-rejects",
        element: <ProductReject />,
        status: IDS.ProductReject.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/seller-detail",
        element: <SellerDetails />,
        status: IDS.SellerDetails.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/employee/employee-requests",
        element: <EmployeeRequest />,
        status: IDS.SellerRequest.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/employee/employee-rejected",
        element: <EmployeeRejected />,
        status: IDS.SellerRejected.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/employee/customer-details",
        element: <CustomerDetails />,
        status: IDS.CustomersDetails.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/employee/contact-us",
        element: <ContactUs />,
        status: IDS.ContactUs.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/employee/info-subscriber",
        element: <SubscribeNewsletter />,
        status: IDS.NewsLatterSubscriber.some((value) =>
          isAllow?.includes(value)
        ),
      },
      {
        path: "/employee/career/application-form",
        element: <CareerApplicationForm />,
        status: IDS.CareerApplication.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/employee/inquire",
        element: <Inquire />,
        status: IDS.Inquire.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/privacy-policy",
        element: <Privacy_Policy />,
        status: IDS.PrivacyPolicy.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/terms-and-conditition",
        element: <Terms_and_Condition />,
        status: IDS.TermAndCondition.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/customization/category-banner",
        element: <CategoryBanner />,
        status: IDS.CategoryBanner.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/customization/faqs",
        element: <Faq />,
        status: IDS.FAQ.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/customization/faq-category",
        element: <FaqCategory />,
        status: IDS.FAQCategory.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/customization/blogs",
        element: <Blogs />,
        status: IDS.Blog.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/employee/role",
        element: <Role />,
        status: IDS.Roles.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/employee/employee-details",
        element: <EmployeeDetailsRole />,
        status: IDS.Roles.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/customization/home-banner",
        element: <HomeBanner />,
        status: IDS.Banner.some((value) => isAllow?.includes(value)),
      },
      // {
      //   path: "/settings/customization/home-ad-banner",
      //   element: <HomeAdBanner />,
      //   status: IDS.HomeAdBanner.some((value) => isAllow?.includes(value)),
      // },

      {
        path: "/masters/report-analysis",
        element: <ReportAnalysis />,
        status: IDS.ReportAnalysis.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/customization/app-setup",
        element: <AppSetup />,
        status: IDS.AppSetup.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/advertisement-banner",
        element: <AdvertisementBanner />,
        status: IDS.AdvertisementBanner.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/settings/customization/web-header",
        element: <WebHeader />,
        status: IDS.WebHeader.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/customization/app-setup",
        element: <AppSetup />,
        status: IDS.AppSetup.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/birthday",
        element: <Birthday />,
        status: IDS.Birthday.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/brands",
        element: <Brands />,
        status: IDS.Brands.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/about-us/about-banner",
        element: <AboutBanner />,
        status: IDS.AboutBanner.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/about-us/our-story",
        element: <OurStory />,
        status: IDS.OurStory.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/about-us/happy-customers",
        element: <HappyCustomers />,
        status: IDS.HappyCustomer.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/about-us/why-choose-us",
        element: <WhyChooseUs />,
        status: IDS.WhyChoose.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/tax/tax-type",
        element: <TaxType />,
        status: IDS.TaxType.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/tax/tax-percentage",
        element: <TaxPercentage />,
        status: IDS.TaxPercentage.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/membership-details",
        element: <MembershipDetails />,
        status: IDS.MembershipDetails.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/masters/features",
        element: <Features />,
        status: IDS.MembershipFeatures.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/notification",
        element: <Notifications />,
        status: IDS.Notification.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/withdrawl-request",
        element: <WithdrawlRequest />,
        status: IDS.WalletWithdrawl.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/wallet-history",
        element: <WalletHistory />,
        status: IDS.WalletHistory.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/complaint-query",
        element: <TicketComplaintQuery />,
        status: IDS.queryComplaint.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/get-all-product-report",
        element: <ProductSellingReport />,
        status: IDS.WalletWithdrawl.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/all-inventry_product",
        element: <ProductStockReport />,
        status: IDS.WalletWithdrawl.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/order",
        element: <OrderReport />,
        status: IDS.WalletWithdrawl.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/get-customer-order-report",
        element: <CustomerOrderReport />,
        status: IDS.WalletWithdrawl.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/product-graph",
        element: <ReportInGraph />,
        status: IDS.WalletWithdrawl.some((value) => isAllow?.includes(value)),
      },

      ///////////////////////////////////////////////////////////Finance Starts///////////////////////////////////////////////////////////////
      {
        path: "/finance",
        element: <Finance />,
        status: IDS.Finance.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/finance-product-report",
        element: <FinanceProductReport />,
        status: IDS.FinanceProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-seller-product-report",
        element: <FinanceSellerProductReport />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-product-wise-sold-report",
        element: <ProductWiseReport />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-seller-subscription-collection",
        element: <FinanceSubscriptionHistory />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-profit-loss",
        element: <FinanceProfitLoss />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-discount-product",
        element: <FinanceProductDiscount />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-coupon-discount",
        element: <FinanceOrderCouponDiscount />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-quick-delivery",
        element: <FinanceQuickDelivery />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-payments",
        element: <FinancePayments />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      {
        path: "/finance-delivery-charges",
        element: <FinanceDeliveryCharges />,
        status: IDS.FinanceSellerProductReport.some((value) =>
          isAllow?.includes(value)
        ),
      },

      ///////////////////////////////////////////////////////////////Finance Ends//////////////////////////////////////////

      {
        path: "/stock-management/order-purchase",
        element: <OrderPurchase />,
        status: IDS.OrderPurchase.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/supplier",
        element: <Supplier />,
        status: IDS.Supplier.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/delivery-boy",
        element: <DeliveryBoy />,
        status: IDS.Delivery.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/settings/child-sub-category",
        element: <ChildSubCategory />,
        status: IDS.ChildSubCategory.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/product-review",
        element: <ProductReview />,
        status: IDS.ProductReview.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/Coupon-codes",
        element: <CouponCodes />,
        status: IDS.CouponCode.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/settings/customization/blog-video",
        element: <BlogVideo />,
        status: IDS.BlogVideo.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/transaction-data/subscription-history",
        element: <SubscriptionHistory />,
        status: IDS.BlogVideo.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/transaction-data/order-transaction-history",
        element: <OrderTransactionHistory />,
        status: IDS.BlogVideo.some((value) => isAllow?.includes(value)),
      },
      {
        path: "/transaction-data/return-order-transaction-history",
        element: <ReturnOrderTransactionHistory />,
        status: IDS.BlogVideo.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/transaction-data/wallet-transaction-history",
        element: <WalletTransactionHistory />,
        status: IDS.BlogVideo.some((value) => isAllow?.includes(value)),
      },

      // ................................................................ Billing Report ........................................................................
      {
        path: "/billing/vendor-billing-report",
        element: <VendorBillingReport />,
        status: IDS.SellerDetails.some((value) => isAllow?.includes(value)),
      },

      {
        path: "/billing/customer-billing-report",
        element: <CustomerBillingReport />,
        status: IDS.SellerDetails.some((value) => isAllow?.includes(value)),
      },
      // ................................................................ Billing Report End ........................................................................

      // ................................................................ Utilities ........................................................................
      // {
      //   path: "/seller-detail",
      //   element: <AcceptedSellerData />,
      //   status: IDS.SellerDetails.some((value) => isAllow?.includes(value)),
      // },

      // {
      //   path: "/seller-detail",
      //   element: <DeliveryBoyTransaction />,
      //   status: IDS.SellerDetails.some((value) => isAllow?.includes(value)),
      // },
      // ................................................................ Utilities End ........................................................................
    ]);
  }, [isAllow]);
  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className=" d-flex ">
          <div
            className={`${
              isSidebarOpen
                ? "sidebarHeadopen "
                : "scale-in-hor-left sidebarHeadClose"
            }`}
          >
            <Sidebar />
          </div>
          <div
            className={`${
              isSidebarOpen
                ? "dashboradopen"
                : "scale-in-hor-right dashbordclose"
            }`}
          >
            <div className="allRoutesMain">
              {/* <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/advanceDashboard"
                  element={<AdvanceDashboard />}
                />
                <Route path="/settingContent" element={<SettingContent />} />
                <Route path="/employee/role" element={<Role />} />
                <Route path="/seller-detail" element={<SellerDetails />} />
                <Route
                  path="/employee/employee-details"
                  element={<EmployeeDetails />}
                />
                <Route
                  path="/employee/employee-requests"
                  element={<EmployeeRequest />}
                />
                <Route
                  path="/employee/employee-rejected"
                  element={<EmployeeRejected />}
                />
                <Route
                  path="/employee/customer-details"
                  element={<CustomerDetails />}
                />
                <Route path="/employee/contact-us" element={<ContactUs />} />{" "}
                <Route
                  path="/employee/info-subscriber"
                  element={<SubscribeNewsletter />}
                />
                <Route
                  path="/employee/career/application-form"
                  element={<CareerApplicationForm />}
                />
                <Route path="/employee/inquire" element={<Inquire />} />
                <Route path="/product" element={<Product />} />
                <Route path="/product-requests" element={<ProductRequests />} />
                <Route path="/product/:id" element={<Product />} />
                <Route
                  path="/about-us/why-choose-us"
                  element={<WhyChooseUs />}
                />
                <Route
                  path="/about-us/happy-customers"
                  element={<HappyCustomers />}
                />
                <Route path="/about-us/our-story" element={<OurStory />} />
                <Route
                  path="/about-us/about-banner"
                  element={<AboutBanner />}
                />
                <Route path="/order-managements/order" element={<Orders />} />
                <Route path="/why_choose_us" element={<WhyChooseUs />} />
                <Route path="/order-managements/orders" element={<Orders />} />
                <Route
                  path="/order-managements/order/refund"
                  element={<OrdersToRefund />}
                />
                <Route
                  path="/order-managements/order/return"
                  element={<ReturnOrders />}
                />
           
                <Route path="/career/work-module" element={<WorkModule />} />
                <Route path="/career/job-type" element={<JobType />} />{" "}
                <Route path="/career/department" element={<Department />} />
                <Route
                  path="/career/qualification"
                  element={<Qualification />}
                />{" "}
                <Route path="/career/jobs" element={<Jobs />} />
              
                <Route path="/settings/category" element={<Category />} />
                <Route
                  path="/settings/sub-category"
                  element={<SubCategory />}
                />
                <Route
                  path="/settings/child-sub-category"
                  element={<ChildSubCategory />}
                />
                <Route path="/settings/attributes" element={<Attributes />} />
                <Route
                  path="/settings/sub-attributes"
                  element={<SubAttributes />}
                />
             
                <Route
                  path="/settings/customization/web-header"
                  element={<WebHeader />}
                />
                <Route
                  path="/settings/customization/app-setup"
                  element={<AppSetup />}
                />
                <Route
                  path="/settings/customization/home-banner"
                  element={<HomeBanner />}
                />
                <Route
                  path="/settings/customization/home-ad-banner"
                  element={<HomeAdBanner />}
                />
                <Route
                  path="/settings/customization/category-banner"
                  element={<CategoryBanner />}
                />
                <Route
                  path="/settings/privacy-policy"
                  element={<Privacy_Policy />}
                />
                <Route
                  path="/settings/terms-and-conditition"
                  element={<Terms_and_Condition />}
                />
                <Route
                  path="/settings/customization/blogs"
                  element={<Blogs />}
                />{" "}
                <Route
                  path="/settings/customization/blog-video"
                  element={<BlogVideo />}
                />
                <Route
                  path="/settings/customization/faq-category"
                  element={<FaqCategory />}
                />
                <Route path="/settings/customization/faqs" element={<Faq />} />
                <Route
                  path="/settings/Coupon-codes"
                  element={<CouponCodes />}
                />
                <Route path="/settings/brands" element={<Brands />} />
               
                <Route
                  path="/settings/location/country"
                  element={<Country />}
                />
                <Route path="/settings/location/state" element={<State />} />
                <Route path="/settings/location/city" element={<City />} />
                <Route
                  path="/settings/location/pincode"
                  element={<Pincode />}
                />
                <Route
                  path="/settings/location/country-codes"
                  element={<CountryCodes />}
                />
            
                <Route path="/settings/tax/tax-type" element={<TaxType />} />
                <Route
                  path="/settings/tax/tax-percentage"
                  element={<TaxPercentage />}
                />
                <Route
                  path="/settings/membership-details"
                  element={<MembershipDetails />}
                />
                <Route
                  path="/settings/notification"
                  element={<Notifications />}
                />
                <Route path="/delivery-boy" element={<DeliveryBoy />} />
                <Route
                  path="/advertisement-banner"
                  element={<AdvertisementBanner />}
                />
                <Route path="/supplier" element={<Supplier />} />
                <Route
                  path="/stock-management/order-purchase"
                  element={<OrderPurchase />}
                />
                <Route path="/finance" element={<Finance />} />
                <Route path="/product-review" element={<ProductReview />} />
                <Route
                  path="/withdrawl-request"
                  element={<WithdrawlRequest />}
                />{" "}
                <Route
                  path="/masters/report-analysis"
                  element={<ReportAnalysis />}
                />
                <Route path="/masters/features" element={<Features />} />
              </Routes> */}
              <Routes>
                <Route path="/" element={<Login />} />
                {urls?.map(
                  (url) =>
                    url.status && (
                      <Route path={url.path} element={url.element} />
                    )
                )}
                {/* <Route path="/Header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} /> */}
                {/* PageNotFound Start  */}
                <Route path="*" element={<PageNotFound />} />{" "}
                {/* PageNotFound End  */}
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
