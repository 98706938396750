import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import colunms from "../../../Components/assets/icons/LINES.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Context } from "../../../utils/context";
import { postData } from "../../../utils/api";
import { Swiper, SwiperSlide } from "swiper/react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/common";
import { Row, Col, Form } from "react-bootstrap";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";
library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data,
    IMG_URL,
    SelectDelivery,
    getDownloadDataExcel,
    usertype,
  } = useContext(Context);

  console.log(usertype, "usertype");

  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [serachProduct, setSerachProduct] = useState("");
  const [serachOrderStatus, setSearchOrderSatatus] = useState("");
  const [serachPaymentStatus, setSearchPaymentSatatus] = useState("");
  const [searchDate, setSearchDate] = useState("");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [pickfrom, setPickFrom] = useState("");
  const [pickto, setPickTo] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/order-return?page=${onPageChange}&per_page=${perPage}&term=${search}` +
        `&customerName=${customerName}&serachProduct=${serachProduct}` +
        `&serachReturnStatus=${
          serachOrderStatus?.value || ""
        }&serachPaymentStatus=${serachPaymentStatus}&date=${searchDate}&from=${
          from ? formatDate(from) : ""
        } &to=${to ? formatDate(to) : ""}&pickfrom=${
          pickfrom ? formatDate(pickfrom) : ""
        } &pickto=${pickto ? formatDate(pickto) : ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    settotalPages(response?.data?.totalPages);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    customerName,
    serachProduct,
    serachOrderStatus,
    serachPaymentStatus,
    searchDate,
    from,
    to,
    pickfrom,
    pickto,
    onPageChange,
  ]);

  useEffect(() => {
    console.log("serachPaymentStatus", serachPaymentStatus);
  }, [serachPaymentStatus]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/order-return/refund/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };
  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/order-return/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col14: true,
    col13: false,
    col15: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };
  const [orderStatus, setOrderStatus] = useState({});
  const [refundStatus, setRefundStatus] = useState({});
  const [deliveryBoy, setDeliveryBoy] = useState({});

  const GetAllReturnStatus = async () => {
    const response = await getData("/all-return-status");

    if (response?.success) {
      setOrderStatus(await Select2Data(response?.data, "return_status_id"));
    }
  };

  const GetAllRefundStatus = async () => {
    const response = await getData("/all-refund-status");

    if (response?.success) {
      setRefundStatus(await Select2Data(response?.data, "refund_status_id"));
    }
  };

  const GetAllDeliveryBoy = async () => {
    const response = await getData("/all-delivery-boy");

    if (response?.success) {
      setDeliveryBoy(await SelectDelivery(response?.data, "delivery_boy_id"));
    }
  };

  useEffect(() => {
    GetAllReturnStatus();
    GetAllDeliveryBoy();
    GetAllRefundStatus();
  }, []);

  useEffect(() => {
    console.log("selectAllChecked", selectAllChecked);
  }, [selectAllChecked]);

  const handleChangeStatus = async (id) => {
    try {
      if (selectAllChecked.length === 0) {
        alert("Please select at least one row");
        return; // Exit the function early if no rows are selected
      }

      const response = await postData("/order-return/updateStatus", {
        selectedIds: selectAllChecked,
        newStatusId: id,
      });

      if (response?.success) {
        alert("Order status updated successfully:", response.data);
        getDataAll();
      } else {
        alert(response?.message);
      }

      console.log("Order status updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating order status:", error);
      // Handle errors if necessary
    }
  };

  const handleChangeRefundStatus = async (id) => {
    try {
      if (selectAllChecked.length === 0) {
        alert("Please select at least one row");
        return; // Exit the function early if no rows are selected
      }

      const response = await postData("/order-return/updateRefundStatus", {
        selectedIds: selectAllChecked,
        newStatusId: id,
      });

      if (response?.success) {
        alert("Fefund status updated successfully:", response.data);
        getDataAll();
      } else {
        alert(response.message);
      }

      console.log("Fefund status updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating order status:", error);
      // Handle errors if necessary
    }
  };

  const handleAssignDeliveryBoy = async (id, delivery_boy_id) => {
    try {
      // if (selectAllChecked.length === 0) {
      //   alert("Please select at least one row");
      //   return; // Exit the function early if no rows are selected
      // }

      const response = await postData("/order-return/assign", {
        selectedIds: id,
        deliveryboyId: delivery_boy_id,
      });

      if (response?.success) {
        alert("Delivery Boy Assign successfully:", response.data);
        getDataAll();
      }

      console.log("Delivery Boy Assign successfully:", response.data);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [showmod, setShowmod] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleShowmod = (id) => {
    setSelectedId(id);
    setShowmod(true);
  };

  const handleClosemod = () => {
    setShowmod(false);
    setSelectedId(null);
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/order-return/download",
          selectAllChecked,
          "Return Order List"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header
          title={"Return Order"}
          link={"/order-managements/order/return"}
        />
        {/* return */}
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-3">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="add">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            // marginLeft: "5px",
                            backgroundColor: "green",
                          }}
                        >
                          <img src={colunms} className="columns me-2 " alt="" />
                          Change Return Status{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {Object.keys(orderStatus).length > 0 &&
                            Object.entries(orderStatus).map(
                              ([id, status]) =>
                                status?.value !== 1 && (
                                  <button
                                    className="dropdown-item"
                                    key={id}
                                    onClick={() =>
                                      handleChangeStatus(status.value)
                                    }
                                  >
                                    {status.label}
                                  </button>
                                )
                            )}
                        </div>

                        {/* <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            // marginLeft: "5px",
                            backgroundColor: "green",
                          }}
                        >
                          <img src={colunms} className="columns me-2 " alt="" />
                          Change Refund Status{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button> */}
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {Object.keys(refundStatus).length > 0 &&
                            Object.entries(refundStatus).map(
                              ([id, status]) =>
                                status?.value !== 1 && (
                                  <button
                                    className="dropdown-item"
                                    key={id}
                                    onClick={() =>
                                      handleChangeRefundStatus(status.value)
                                    }
                                  >
                                    {status.label}
                                  </button>
                                )
                            )}
                        </div>

                        {/* <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            // marginLeft: "5px",
                            backgroundColor: "green",
                          }}
                        >
                          <img src={colunms} className="columns me-2 " alt="" />
                          Assign Delivery Boy{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {Object.keys(deliveryBoy).length > 0 &&
                            Object.entries(deliveryBoy).map(([id, status]) => (
                              <button
                                className="dropdown-item"
                                key={id}
                                onClick={() =>
                                  handleAssignDeliveryBoy(status.value)
                                }
                              >
                                {status.label || `Delivery Boy ${id}`}
                              </button>
                            ))}
                        </div> */}
                      </div>
                    </div>
                    <div className="border-line my-3"></div>
                    <div className="row pt-4">
                      <div className=" col-12 ">
                        <div className="d-flex mb-3 align-items-center">
                          <div className="show me-2">
                            <p className="show m-0">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries me-5">
                            <p className="show m-0">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row align-items-end">
                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num me-2">
                              <Form.Label></Form.Label>
                              <Select
                                options={orderStatus}
                                placeholder="Select Order Status"
                                value={serachOrderStatus}
                                onChange={(e) => {
                                  setSearchOrderSatatus(e);
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>
                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num me-2">
                              <Form.Label></Form.Label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Customer Name"
                                id=""
                                value={customerName}
                                onChange={(e) => {
                                  setCustomerName(e.target.value);
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label>Return Request From</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={from}
                              onChange={(e) => {
                                setFrom(e.target.value);
                                handlePageChange(1);
                              }}
                            />
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label>Return Request To</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={to}
                              onChange={(e) => {
                                setTo(e.target.value);
                                handlePageChange(1);
                              }}
                              min={from}
                            />
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label>Picked From</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={pickfrom}
                              onChange={(e) => {
                                setPickFrom(e.target.value);
                                handlePageChange(1);
                              }}
                            />
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label>Picked To</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={pickto}
                              onChange={(e) => {
                                setPickTo(e.target.value);
                                handlePageChange(1);
                              }}
                              min={pickfrom}
                            />
                          </div>

                          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12 mb-2">
                            <button
                              className="btn btn-success w-100"
                              onClick={HandleDownload}
                            >
                              Download data in Excel
                              <FontAwesomeIcon
                                icon="fa-solid fa-file-lines"
                                className="ms-2"
                              />
                            </button>
                          </div>

                          <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                            <div className="Search-1">
                              <Form.Label></Form.Label>
                              <button
                                type="button"
                                onClick={() => {
                                  setSearchPaymentSatatus("");
                                  setSearchOrderSatatus("");
                                  setSerachProduct("");
                                  setCustomerName("");
                                  setSearchDate("");
                                  setFrom("");
                                  setTo("");
                                  setPickFrom("");
                                  setPickTo("");
                                  setReset(!reset);
                                }}
                                className="btn btn-reset w-100"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line my-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            <th>
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                              Select
                            </th>
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">Invoice No.</th>
                            )}
                            {visible.col5 && (
                              <th className="tax-name">Product Name</th>
                            )}
                            {visible.col3 && (
                              <th className="tax-name">Customer Name</th>
                            )}{" "}
                            {visible.col15 && (
                              <th className="tax-name">
                                Customer Mobile Number
                              </th>
                            )}
                            {visible.col6 && (
                              <th className="tax-name">Amount</th>
                            )}{" "}
                            {visible.col14 && (
                              <th className="tax-name">Return Request Date</th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name">PickUp Date</th>
                            )}
                            {visible.col8 && (
                              <th className="tax-name">Return Status</th>
                            )}
                            {visible.col9 && (
                              <th className="tax-name">Images</th>
                            )}
                            {visible.col10 && (
                              <th className="tax-name">Delivery Boy</th>
                            )}
                            {visible.col13 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.data?.length > 0 ? (
                            data.data.data.map((d, index) => {
                              const paginatedIndex =
                                (onPageChange - 1) * perPage + index + 1;
                              return (
                                <tr className="" key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value={d?.id}
                                      name="perselected"
                                      checked={selectAllChecked.includes(d?.id)}
                                      onChange={handleChange}
                                      id="flexCheckDefault"
                                    ></input>
                                  </td>
                                  {visible.col1 && <td>{paginatedIndex}.</td>}{" "}
                                  {visible.col2 && (
                                    <td>{d?.orders_detail?.invoice_no}</td>
                                  )}
                                  {visible.col5 && (
                                    <td>{d?.orders_detail?.product?.name}</td>
                                  )}
                                  {visible.col3 && (
                                    <td>
                                      {
                                        d?.orders_detail?.order?.user
                                          ?.first_name
                                      }
                                    </td>
                                  )}{" "}
                                  {visible.col15 && (
                                    <td>
                                      {
                                        d?.orders_detail?.order?.user
                                          ?.contact_no
                                      }
                                    </td>
                                  )}
                                  {visible.col6 && (
                                    // <td>{d?.orders_detail?.price}</td>
                                    <td>
                                      {(Number(d?.orders_detail?.price) || 0) *
                                        (Number(d?.orders_detail?.quantity) ||
                                          1) -
                                        (Number(d?.orders_detail?.discount) ||
                                          0) -
                                        (Number(
                                          d?.orders_detail?.membershipDiscount
                                        ) || 0) +
                                        ((Number(d?.orders_detail?.price) ||
                                          0) *
                                          (Number(
                                            d?.orders_detail?.tax_percent
                                          ) || 0)) /
                                          100}
                                    </td>
                                  )}
                                  {visible.col14 && (
                                    <td>
                                      {d?.createdAt
                                        ? new Date(
                                            d.createdAt
                                          ).toLocaleDateString()
                                        : ""}
                                    </td>
                                  )}
                                  {visible.col4 && (
                                    <td>
                                      {d?.pickup_date
                                        ? new Date(
                                            d.pickup_date
                                          ).toLocaleDateString()
                                        : ""}
                                    </td>
                                  )}
                                  {visible.col8 && (
                                    <td>{d?.return_status?.name}</td>
                                  )}
                                  {visible.col9 && (
                                    <td className="text-center">
                                      <Modal
                                        show={showmod && selectedId === d.id}
                                        onHide={handleClosemod}
                                        centered
                                        size="xl"
                                        animation={true}
                                      >
                                        <Modal.Header
                                          closeButton
                                        ></Modal.Header>
                                        <Modal.Body>
                                          <div className="returnod-allimgdiv">
                                            {/* <Swiper
                                              slidesPerView={1}
                                              spaceBetween={10}
                                              pagination={{
                                                clickable: true,
                                              }}
                                              breakpoints={{
                                                575: {
                                                  slidesPerView: 2,
                                                  spaceBetween: 20,
                                                },
                                                768: {
                                                  slidesPerView: 2,
                                                  spaceBetween: 40,
                                                },
                                                1024: {
                                                  slidesPerView: 3,
                                                  spaceBetween: 50,
                                                },
                                              }}
                                              modules={[Pagination]}
                                              className="mySwiper"
                                            > */}
                                            {d?.return_order_images?.map(
                                              (value, index) => (
                                                <SwiperSlide key={index}>
                                                  <img
                                                    src={IMG_URL + value.image}
                                                    alt={`Image ${index}`}
                                                    className="sliderrimgg"
                                                  />
                                                </SwiperSlide>
                                              )
                                            )}
                                            {/* </Swiper> */}
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                      <button
                                        onClick={() => handleShowmod(d.id)}
                                        className="viewimgbtn btn"
                                        type="button"
                                      >
                                        View
                                      </button>
                                    </td>
                                  )}
                                  <td>
                                    {usertype === "Finance" ? (
                                      d.delivery_boy?.first_name
                                    ) : d.delivery_boy_id === null ? (
                                      <>
                                        <button
                                          className="btn btn-columns dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          style={{ backgroundColor: "green" }}
                                        >
                                          <img
                                            src={colunms}
                                            className="columns me-2"
                                            alt=""
                                          />
                                          Assign Delivery Boy
                                          <img
                                            src={top}
                                            className="top ms-1"
                                            alt=""
                                          />
                                        </button>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          {Object.keys(deliveryBoy).length >
                                            0 &&
                                            Object.entries(deliveryBoy).map(
                                              ([id, status]) => (
                                                <button
                                                  className="dropdown-item"
                                                  key={id}
                                                  onClick={() =>
                                                    handleAssignDeliveryBoy(
                                                      d.id,
                                                      status.value
                                                    )
                                                  }
                                                >
                                                  {status.label ||
                                                    `Delivery Boy ${id}`}
                                                </button>
                                              )
                                            )}
                                        </div>
                                      </>
                                    ) : (
                                      d.delivery_boy?.first_name
                                    )}
                                  </td>
                                  {d.return_status?.id === 4 &&
                                    usertype == "Finance" &&
                                    visible.col11 && (
                                      <td>
                                        {
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                              // ChangeStatus(d.id);
                                            }}
                                          >
                                            Return Money
                                          </button>
                                        }
                                      </td>
                                    )}
                                  {/* {d.return_status?.id == 5 &&
                                  "Refund Successful"} */}
                                  {visible.col13 && (
                                    <td className="text-center">
                                      {d?.id === 1 ? (
                                        <></>
                                      ) : (
                                        <div className="d-flex"></div>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-data-holder">
                              <td colSpan={13}>
                                <p>
                                  <b>No Return Order found</b>
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>{" "}
                      <Pagination_Holder
                        onPageChange={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
