import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import plus from "../../Components/assets/icons/a1.png";
import colunms from "../../Components/assets/icons/LINES.png";
import pen from "../../Components/assets/icons/pen.png";
import basket from "../../Components/assets/icons/basket.png";
import search1 from "../../Components/assets/icons/search.png";
import top from "../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../assets/icons/circle.png";
import rigth from "../assets/icons/rigth.png";
import save from "../assets/icons/save.png";
import cancel from "../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";

import { formatDate, timeShow } from "../../utils/common";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

import Pagination from "react-bootstrap/Pagination";
import Pagination_Holder from "../common/Pagination_Holder/Pagination_Holder";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    getDownloadDataExcel,
    Per_Page_Dropdown,
    postData,
  } = useContext(Context);
  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [stage, setStage] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [searchDate, setSearchDate] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/withdrawl-request?page=${onPageChange}&per_page=${perPage}&term=${search}&stage=${stage}&date=${
        searchDate ? formatDate(searchDate) : ""
      }`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    settotalPages(response?.data?.totalPages);
    // setCurrentPage(response?.data?.);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    search,
    stage,
    searchDate,
    onPageChange,
  ]);

  const ChangeStage = async (id, appr) => {
    const response = await postData(`/withdrawl-request/request/${id}`, appr);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const [showTakeAnAction, setTakeAnActionShow] = useState(false);

  const handleTakeAnActionClose = () => setTakeAnActionShow(false);
  const handleTakeAnActionShow = () => setTakeAnActionShow(true);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },

    watch,
    getValues,
  } = useForm();

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/withdrawl-request/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/withdrawl-request/downloads",
          selectAllChecked,
          "WithDrawl Request list"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Withdrawal Request List"} link={"/withdrawl-request"} />
        <section className="AdvanceDashboard">
          <div className="row ">
            <div className="col-lg-12">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    <div className="row">
                      <div className="d-flex">
                        <div className="add me-1">
                          <div className="dropdown">
                         
                          </div>
                        </div>

                        {/* <div className="add">
                      <Button
                        variant="success"
                        className="btn btn-view"
                        onClick={HandleDownload}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon="fa-solid fa-download"
                          className="me-2"
                        />
                        Download
                      </Button>
                    </div> */}
                      </div>
                      <div className="border-line my-3"></div>
                      <div className="row ">
                        <div className=" col-12">
                          <div className="d-flex mb-3 align-items-center">
                            <div className="show me-2">
                              <p className="show m-0">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries me-5">
                              <p className="show m-0">entries</p>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 ">
                              <div className="sowing me-2">
                                <p className="show m-0">{`Showing ${Math.min(
                                  (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                  currentPage * perPage,
                                  data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-12">
                          <div className="row align-items-end">
                            <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num me-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  id=""
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>

                              {/* <div className="Search me-2">
                                <button
                                  type="button"
                                  onClick={getDataAll}
                                  className="btn btn-search btn btn-primary w-100"
                                >
                                  <img
                                    src={search1}
                                    className="search"
                                    alt=""
                                  />
                                </button>
                              </div> */}
                            </div>

                            <div className="  col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              {" "}
                              <div className="num me-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Stage "
                                  id=""
                                  value={stage}
                                  onChange={(e) => {
                                    setStage(e.target.value);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num me-2">
                                <input
                                  type="date"
                                  className="form-control"
                                  id=""
                                  value={searchDate}
                                  onChange={(e) => {
                                    setSearchDate(e.target.value);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12 mb-2">
                              <button
                                className="btn btn-success "
                                onClick={HandleDownload}
                              >
                                Download data in Excel
                                <FontAwesomeIcon
                                  icon="fa-solid fa-file-lines"
                                  className="ms-2"
                                />
                              </button>
                            </div>

                            <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                              <div className="Search-1">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSearch("");
                                    setSearchDate("");
                                    setStage("");
                                    setReset(!reset);
                                  }}
                                  className="btn btn-reset w-100"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line my-3"></div>
                  <div className="row mt-3">
                    <div className="data  withdrow-table-holder">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            <th className="check round-check">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                ></label>
                              </div>
                            </th>
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name" style={{ width: "20%" }}>
                                Customer
                              </th>
                            )}
                            {visible.col3 && (
                              <th className="tax-name" style={{ width: "20%" }}>
                                Amount
                              </th>
                            )}{" "}
                            {visible.col5 && (
                              <th className="tax-name" style={{ width: "20%" }}>
                                Request Date
                              </th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name" style={{ width: "20%" }}>
                                Action
                              </th>
                            )}{" "}
                            {visible.col6 && (
                              <th className="tax-name" style={{ width: "20%" }}>
                                Status
                              </th>
                            )}
                            {/* {visible.col4 && <th className="active">Action</th>} */}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.data?.length > 0 ? (
                            data.data.data.map((d, index) => {
                              const paginatedIndex =
                                (onPageChange - 1) * perPage + index + 1;
                              return (
                                <tr className="" key={index}>
                                  <td>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={d?.id}
                                        name="perselected"
                                        checked={selectAllChecked.includes(
                                          d?.id
                                        )}
                                        onChange={handleChange}
                                        id={`flexCheckDefault-${index}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexCheckDefault-${index}`}
                                      ></label>
                                    </div>
                                  </td>
                                  {visible.col1 && <td>{paginatedIndex}.</td>}
                                  {visible.col2 && (
                                    <td>{d?.user?.first_name}</td>
                                  )}
                                  {visible.col3 && <td>{d?.amount}</td>}
                                  {visible.col5 && (
                                    <td>
                                      {new Date(
                                        d?.createdAt
                                      ).toLocaleDateString()}
                                    </td>
                                  )}
                                  {visible.col4 && (
                                    <td>
                                      {d.request_status === "approved" ? (
                                        "Approved"
                                      ) : d.request_status === "rejected" ? (
                                        "Rejected"
                                      ) : (
                                        <Col md={6}>
                                          <div className="main-form-section mt-3">
                                            <Form.Group>
                                              <Form.Label>
                                                Take an action
                                              </Form.Label>
                                              <InputGroup>
                                                <Controller
                                                  name={`stage-${index}`}
                                                  control={control}
                                                  rules={{
                                                    required: "Take an Action",
                                                  }}
                                                  render={({
                                                    field,
                                                    fieldState: { error },
                                                  }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        styles={{
                                                          control: (
                                                            baseStyles
                                                          ) => ({
                                                            ...baseStyles,
                                                            borderColor: error
                                                              ? "red"
                                                              : baseStyles.borderColor,
                                                          }),
                                                          menuPortal: (
                                                            base
                                                          ) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                          }),
                                                        }}
                                                        onChange={(
                                                          selectedOption
                                                        ) => {
                                                          field.onChange(
                                                            selectedOption
                                                          );
                                                          ChangeStage(
                                                            d.id,
                                                            selectedOption
                                                          );
                                                        }}
                                                        options={[
                                                          {
                                                            value: "Approved",
                                                            label: "Approved",
                                                          },
                                                          {
                                                            value: "Rejected",
                                                            label: "Rejected",
                                                          },
                                                          {
                                                            value: "Inprogress",
                                                            label: "In Process",
                                                          },
                                                        ]}
                                                        isDisabled={
                                                          d.stage === "Rejected"
                                                        }
                                                      />
                                                      {error && (
                                                        <p className="error-message">
                                                          {error.message}
                                                        </p>
                                                      )}
                                                    </>
                                                  )}
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </div>
                                        </Col>
                                      )}
                                    </td>
                                  )}

                                  {visible.col6 && (
                                    <td>
                                      {d?.request_status
                                        ? d?.request_status
                                        : "Requested"}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="10" className="text-center">
                                Data not available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>{" "}
                      <Pagination_Holder
                        onPageChange={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="take-an-action-modal-holder">
        <Modal
          className="take-an-action-modal "
          show={showTakeAnAction}
          onHide={handleTakeAnActionClose}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Take an action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Group>
              <InputGroup>
                <Controller
                  // name={`stage-${index}`} // name of the field
                  control={control}
                  rules={{
                    required: "Take an Action",
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.stage
                            ? "red"
                            : baseStyles.borderColor,
                        }),
                      }}
                      options={[
                        {
                          value: "Approved",
                          label: "Approve",
                        },
                        {
                          value: "Rejected",
                          label: "Reject",
                        },
                      ]}
                    />
                  )}
                />
              </InputGroup>
              {errors.stage && (
                <p style={{ color: "red" }}>{errors.stage.message}</p>
              )}
            </Form.Group> */}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-no" onClick={handleTakeAnActionClose}>
              Close
            </Button>
            <Button className="btn-yes" onClick={handleTakeAnActionClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
