import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { RegxExpression } from "../../../utils/common";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Uploader } from "uploader";
// import { UploadButton } from "react-uploader";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import Successfull_Modal from "../../common/Successfull_Modal/Successfull_Modal";

function StepEight({ user_id, handleClose }) {
  const [modalSuccessShow, setModalSuccessShow] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
  // const [user_id, setUserId] = useState("");

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm();

  const imageFile = watch("document");

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data?.id) {
      formData.append("id", data?.id);
    }
    formData.append("user_id", user_id);
    formData.append("document", data?.document);
    formData.append("transaction_id", data?.transaction_id);
    formData.append("payment_type", data?.payment_type);
    formData.append("payment_rs", data?.payment_rs);

    try {
      const res = await postData(
        "/employee/seller-details/on-boarding-payment-form",
        formData
      );
      if (res?.success) {
        setTimeout(() => {
          setModalSuccessShow(false);

          handleClose(true);
        }, 2000);
        // props.nextStep();
      } else {
        // Handle the case when res.success is false
        console.error("Failed to submit data");
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(
      `/employee/seller-details/on-boarding-payment-form`
    );
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    getEditData();
  }, [user_id]);

  // useEffect(() => {
  //   setUserId(Cookies.get("user_id"));
  // }, []);

  return (
    <>
      <section className="personal-details-form userForm">
        <div className="details-form-holder">
          <div className="form-container">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required form-field">
                          Transaction Id
                        </Form.Label>

                        <Form.Control
                          type="number"
                          name="transaction_id"
                          placeholder="Percent I Get"
                          {...register("transaction_id", {
                            required: "Percent  is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.transaction_id,
                         
                          })}
                        />

                        {errors.transaction_id && (
                          <span className="text-danger">
                            {errors.transaction_id.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>{" "}
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <div>
                    <Form.Label className="required">Payment Type *</Form.Label>
                    <Form.Select
                      name="payment_type"
                      {...register("payment_type", {
                        required: "Payment Type is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.payment_type,
                      })}
                    >
                      <option value="">Select Payment Rupees</option>
                      <option value="online">Online</option>
                      <option value="cash">Cash</option>
                    </Form.Select>
                    {errors.payment_rs && (
                      <span className="text-danger">
                        {errors.payment_rs.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="field-bottom">
                  <div>
                    <Form.Label className="required">Payment Rs *</Form.Label>
                    <Form.Select
                      name="payment_rs"
                      {...register("payment_rs", {
                        required: "Payment Rupees is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.payment_rs,
                      })}
                    >
                      <option value="">Select Payment Rupees</option>
                      <option value="99">99</option>
                      <option value="999">999</option>
                    </Form.Select>
                    {errors.payment_rs && (
                      <span className="text-danger">
                        {errors.payment_rs.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-field mb-3">
                  <Form.Group controlId="Last_Name">
                    <Form.Label className="required">
                      Upload Payment Image*
                    </Form.Label>
                    <div>
                      <div>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.document,
                          })}
                          type="file"
                          {...register("document", {
                            validate: async (value) => {
                              if (typeof value !== "string") {
                                const fileTypes = ["jpg", "png", "jpeg"];
                                const fileType = value[0]?.name?.split(".")[1];

                                if (!fileTypes.includes(fileType)) {
                                  return `please upload a valid file format. (${fileTypes})`;
                                }
                              }
                            },
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className="main-form-section mt-3">
                <Form.Label>Document Preview </Form.Label>

                {typeof getValues("document") == "string" ? (
                  <div
                    className="image-preview-container mt-3"
                    style={{ marginLeft: "110px" }}
                  >
                    <img
                      src={IMG_URL + getValues("document")}
                      alt="Preview"
                      className="image-preview"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                ) : (
                  imageFile &&
                  imageFile?.length > 0 && (
                    <div
                      className="image-preview-container mt-3"
                      style={{ marginLeft: "110px" }}
                    >
                      <img
                        // src={URL.createObjectURL(getValues("image")[0])}
                        src={URL?.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                  )
                )}
              </div>

              <div className="">
                <div className=" text-end apply_now_btn">
                  {/* <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button> */}
                  <Button
                    type="submit"
                    className="tabs-btn"
                    onClick={() => setModalSuccessShow(true)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
      <Successfull_Modal
        show={modalSuccessShow}
        onHide={() => setModalSuccessShow(false)}
        message={"On Boarding Payment Form Submitted Successfully"}
        // subMessage={"fgdfg"}
      />
    </>
  );
}

export default StepEight;
