import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button } from "react-bootstrap";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import parse from "html-react-parser";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    Select2Data,
    IMG_URL,
  } = useContext(Context);
  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [exp_to, setExpTo] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchCountry, setSearchCountry] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchWorkModule, setSearchWorkModule] = useState("");
  const [searchJobType, setSearchJobType] = useState("");
  const [searchQualification, setSearchQualification] = useState("");
  const [searchDepartment, setSearchDepartment] = useState("");

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await getData(
      `/career/jobs?page=${onPageChange}&per_page=${perPage}
      &term=${search}&exp_to=${exp_to}&searchCountry=${
        searchCountry?.value || ""
      }&searchState=${searchState?.value || ""}&searchCity=${
        searchCity?.value || ""
      }&searchWorkModule=${searchWorkModule?.value || ""}&searchJobType=${
        searchJobType?.value || ""
      }&searchQualification=${
        searchQualification?.value || ""
      }&searchDepartment=${searchDepartment?.value || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setExpTo(response?.data?.exp_to);
    settotalPages(response?.data?.totalPages);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    search,
    exp_to,
    searchCountry,
    searchState,
    searchCity,
    searchWorkModule,
    searchJobType,
    searchQualification,
    searchDepartment,
    onPageChange,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/career/jobs/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/career/jobs/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
    col14: true,
    col15: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [showoff, setShowoff] = useState(false);

  const BulkUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const response = await postData(
        "/masters/child-sub-category/bulk",
        formData,
        {}
      );
      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
      } else {
        setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        setShowoff(response?.data);
      }, 1000);
      getDataAll();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const Sample = async (e) => {
    try {
      await getDownloadDataExcel(
        "/masters/child-sub-category/sample",
        {},
        "state"
      );
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [workModule, setWorkModule] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [department, setDepartment] = useState([]);

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllState = async (id) => {
    const response = await getData(`/allstate/${id}`);

    if (response?.success) {
      setState(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCity = async (id) => {
    const response = await getData(`/allcity/${id}`);

    if (response?.success) {
      setCity(await Select2Data(response?.data, "city_id"));
    }
  };

  const GetAllWorkModule = async () => {
    const response = await getData(`/all-work-module`);

    if (response?.success) {
      setWorkModule(await Select2Data(response?.data, "work_module_id"));
    }
  };

  const GetAllJobType = async () => {
    const response = await getData(`/all-job-type`);

    if (response?.success) {
      setJobType(await Select2Data(response?.data, "job_type_id"));
    }
  };

  const GetAllQualification = async () => {
    const response = await getData(`/all-qualification`);

    if (response?.success) {
      setQualification(await Select2Data(response?.data, "qualification_id"));
    }
  };

  const GetAllDepartment = async () => {
    const response = await getData(`/all-department`);

    if (response?.success) {
      setDepartment(await Select2Data(response?.data, "department_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    GetAllState();
    GetAllCity();
    GetAllWorkModule();
    GetAllJobType();
    GetAllQualification();
    GetAllDepartment();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Jobs"} link={"/career/jobs"} />
        <div className="row">
          <section className="AdvanceDashboard">
            <div className="col-xxl-12 col-xl-12  ">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="add ">
                          {isAllow?.includes(238) ? (
                            <Link
                              // to="/masters/child-sub-category/add"
                              type="button"
                              className="btn btn-add pe-3"
                            >
                              <div onClick={() => handleShow()}>
                                <p className="add-sub-txttt">
                                  {" "}
                                  <img
                                    src={plus}
                                    className="plus me-2 ms-0"
                                    alt=""
                                  />{" "}
                                  Add Jobs
                                </p>
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="add ">
                          <div className="dropdown">
                          
                          </div>
                        </div>
                      </div>
                      <div className="border-line my-3"></div>
                      <div className="row ">
                        <div className=" col-12">
                          <div className="d-flex mb-3 align-items-center">
                            <div className="show me-2">
                              <p className="show m-0">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries me-5">
                              <p className="show m-0">entries</p>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12">
                              <div className="sowing">
                                <p className="show m-0">{`Showing ${Math.min(
                                  (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                  currentPage * perPage,
                                  data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-12">
                          <div className="row align-items-end">
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <Select
                                  isSearchable
                                  options={country}
                                  value={searchCountry}
                                  placeholder="Select Country"
                                  onChange={(e) => {
                                    setSearchCountry(e);
                                    GetAllState(e.value);
                                    setSearchState("");
                                    setSearchCity("");
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <Select
                                  isSearchable
                                  options={state}
                                  value={searchState}
                                  placeholder="Select State"
                                  onChange={(e) => {
                                    setSearchState(e);
                                    GetAllCity(e.value);
                                    setSearchCity("");
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <Select
                                  isSearchable
                                  options={city}
                                  value={searchCity}
                                  placeholder="Select City"
                                  onChange={(e) => {
                                    setSearchCity(e);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <Select
                                  isSearchable
                                  options={workModule}
                                  value={searchWorkModule}
                                  placeholder="Select Work Module"
                                  onChange={(e) => {
                                    setSearchWorkModule(e);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <Select
                                  isSearchable
                                  options={jobType}
                                  value={searchJobType}
                                  placeholder="Select Job Type"
                                  onChange={(e) => {
                                    setSearchJobType(e);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <Select
                                  isSearchable
                                  options={qualification}
                                  value={searchQualification}
                                  placeholder="Select Qualification"
                                  onChange={(e) => {
                                    setSearchQualification(e);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <Select
                                  isSearchable
                                  options={department}
                                  value={searchDepartment}
                                  placeholder="Select Department"
                                  onChange={(e) => {
                                    setSearchDepartment(e);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  id=""
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                              <div className="num ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Experience "
                                  id=""
                                  value={exp_to}
                                  onChange={(e) => {
                                    setExpTo(e.target.value);
                                    handlePageChange(1);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                              <div className="Search-1">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSearch("");
                                    setExpTo("");
                                    setSearchCountry("");
                                    setSearchState("");
                                    setSearchCity("");
                                    setSearchWorkModule("");
                                    setSearchJobType("");
                                    setSearchQualification("");
                                    setSearchDepartment("");
                                    setReset(!reset);
                                  }}
                                  className="btn btn-reset w-100"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line my-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr">Sr. No.</th>}
                              {visible.col2 && (
                                <th className="tax-name">Country</th>
                              )}
                              {visible.col3 && (
                                <th className="tax-name">State</th>
                              )}
                              {visible.col4 && (
                                <th className="tax-name">City</th>
                              )}{" "}
                              {visible.col5 && (
                                <th className="tax-name">Name</th>
                              )}{" "}
                              {visible.col15 && (
                                <th className="tax-name">Image</th>
                              )}
                              {visible.col6 && (
                                <th className="tax-name">Experience</th>
                              )}{" "}
                              {visible.col7 && (
                                <th className="tax-name">Work Module</th>
                              )}{" "}
                              {visible.col8 && (
                                <th className="tax-name">Job Type</th>
                              )}{" "}
                              {visible.col9 && (
                                <th className="tax-name">Qualification</th>
                              )}{" "}
                              {visible.col10 && (
                                <th className="tax-name">Department</th>
                              )}{" "}
                              {visible.col11 && (
                                <th className="tax-name">
                                  Job Responsibilities
                                </th>
                              )}{" "}
                              {visible.col12 && (
                                <th className="tax-name">Requirement</th>
                              )}
                              {visible.col13 && (
                                <th className="tax-name">Status</th>
                              )}
                              {visible.col14 && (
                                <th className="active">Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.length > 0 ? (
                              data.data.data.map((d, index) => {
                                const paginatedIndex =
                                  (onPageChange - 1) * perPage + index + 1;
                                return (
                                  <tr className="" key={index}>
                                    {visible.col1 && <td>{paginatedIndex}.</td>}{" "}
                                    {visible.col2 && (
                                      <td>{d?.country?.name}</td>
                                    )}
                                    {visible.col3 && <td>{d?.state?.name}</td>}
                                    {visible.col4 && (
                                      <td>{d?.city?.name}</td>
                                    )}{" "}
                                    {visible.col5 && <td>{d?.name}</td>}
                                    {visible.col15 && (
                                      <td>
                                        {d?.image && (
                                          <img
                                            src={IMG_URL + d?.image}
                                            alt="Image"
                                            width="100"
                                            height="100"
                                          />
                                        )}
                                      </td>
                                    )}
                                    {visible.col6 && (
                                      <td>
                                        {d?.exp_from}-{d?.exp_to} Years
                                      </td>
                                    )}
                                    {visible.col7 && (
                                      <td>{d?.work_module?.name}</td>
                                    )}
                                    {visible.col8 && (
                                      <td>{d?.job_type?.name}</td>
                                    )}
                                    {visible.col9 && (
                                      <td>{d?.qualification?.name}</td>
                                    )}
                                    {visible.col10 && (
                                      <td>{d?.department?.name}</td>
                                    )}
                                    {visible.col11 && (
                                      <td>{parse(d?.job_responsibilities)}</td>
                                    )}
                                    {visible.col12 && (
                                      <td>{parse(d?.requirement)}</td>
                                    )}
                                    {visible.col13 && (
                                      <td>
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={d.status}
                                            onChange={() => {
                                              ChangeStatus(d.id);
                                            }}
                                            disabled={!isAllow?.includes(237)}
                                            id={`flexSwitchCheckDefault${d?.id}`}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                          >
                                            {d.status ? "Active" : "Inactive"}
                                          </label>
                                        </div>
                                      </td>
                                    )}
                                    {visible.col14 && (
                                      <td>
                                        <div className="d-flex">
                                          {isAllow?.includes(239) ? (
                                            <Button
                                              // to={`/masters/child-sub-category/edit/${d?.id}`}
                                              onClick={() => handleShow1(d?.id)}
                                              type="button"
                                              className="btn btn-primary me-1"
                                            >
                                              <img
                                                src={pen}
                                                className="pen"
                                                alt=""
                                              />
                                            </Button>
                                          ) : (
                                            <></>
                                          )}
                                          {isAllow?.includes(240) ? (
                                            <button
                                              onClick={() => {
                                                showDeleteRecord(
                                                  d?.id,
                                                  d?.name
                                                );
                                              }}
                                              type="button"
                                              className="btn btn-danger"
                                            >
                                              <img
                                                src={basket}
                                                className="pen"
                                                alt=""
                                              />
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="15" className="text-center">
                                  Data not available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>{" "}
                        <Pagination_Holder
                          onPageChange={currentPage}
                          totalPages={totalPages}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />

      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br />
                  Dependent Data Also Be Deleted And
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
